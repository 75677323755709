import { useState, useRef, useEffect } from "react";
import "./UpdateUser.css";
import Modal from "react-bootstrap/Modal";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import { updateUser } from "../../../services/user";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import defaultImage from "../../../assets/images/blank-profile-picture-973460_1280.webp";

export const UpdateUser = (props) => {
  // const defaultImage =
  //   "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png";
  const { data } = props;
  const inputFile = useRef(null);
  const [img, setImg] = useState(defaultImage);
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false);

  const handleOpenImageSelector = () => {
    inputFile.current.click();
  };

  const handleImageChange = (e) => {
    if (e.target.files[0] === undefined) {
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);

    reader.onload = () => {
      setImg(reader.result);
    };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    const res = await updateUser(
      img === defaultImage ? defaultImage : img,
      username,
      data?.user_email
    );

    console.log("update user: ", res);

    if (res === null) {
      toast.error("Unable to update user, please try later.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setLoading(false);
      return;
    }

    props.userUpdated();
    props.onHide();
    setLoading(false);
    return;
  };

  useEffect(() => {
    setUsername(data !== null ? data.user_name : "");

    setImg(
      data?.user_profile_pic_url !== null
        ? data?.user_profile_pic_url
        : defaultImage
    );
  }, [data]);

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        containerId="updateNotification"
      />
      <ToastContainer />
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Update User
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Box component="form" onSubmit={handleSubmit}>
          <div className="updateImageContainer">
            <img className="updateProfileImage" src={img} alt="Profile image" />
            <IconButton aria-label="edit" onClick={handleOpenImageSelector}>
              <EditIcon fontSize="small" />
            </IconButton>
          </div>
          <input
            type="file"
            id="file"
            accept="image/*"
            ref={inputFile}
            style={{ display: "none" }}
            onChange={handleImageChange}
          />
          <TextField
            sx={{ mt: 2 }}
            label="Username"
            variant="outlined"
            fullWidth
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            autoFocus
            required
          />
          <TextField
            sx={{ mt: 2 }}
            label="Email"
            variant="outlined"
            fullWidth
            type="email"
            value={data?.user_email}
            disabled
          />
          <div className="updateBtnContainer">
            <Button
              size="small"
              variant="contained"
              color="error"
              onClick={props.onHide}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              size="small"
              variant="contained"
              // className="customBtn"
              color="primary"
            >
              {loading ? "Loading..." : "Update"}
            </Button>
          </div>
        </Box>
      </Modal.Body>
    </Modal>
  );
};
