// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.manufacturerCouponsContainer {
  gap: 10px;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  border-radius: 30px;
  align-items: center;
  border: 1px solid #e4e4e4;
  justify-content: flex-start;
}
`, "",{"version":3,"sources":["webpack://./src/pages/manufacturerCoupon/ManufacturerCoupon.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,aAAa;EACb,aAAa;EACb,eAAe;EACf,mBAAmB;EACnB,mBAAmB;EACnB,yBAAyB;EACzB,2BAA2B;AAC7B","sourcesContent":[".manufacturerCouponsContainer {\n  gap: 10px;\n  padding: 20px;\n  display: flex;\n  flex-wrap: wrap;\n  border-radius: 30px;\n  align-items: center;\n  border: 1px solid #e4e4e4;\n  justify-content: flex-start;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
