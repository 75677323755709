// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css-1qvr50w-MuiTypography-root {
  font-size: 11px !important;
  /* word-wrap: break-word;
  white-space: nowrap;
  width: 50px;
  overflow: scroll;
  text-overflow: clip; */
}

.css-nrdprl-MuiTypography-root {
  font-size: 11px !important;
  /* word-wrap: break-word; */
}

.css-o69gx8-MuiCardMedia-root {
  width: -moz-fit-content !important;
  width: fit-content !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.css-185gdzj-MuiCardHeader-root {
  padding: 5px !important;
}
.css-185gdzj-MuiCardHeader-root span {
  word-break: break-all;
}
.css-1t6e9jv-MuiCardActions-root {
  padding: 0px !important;
}
.Barcode {
  padding: 1rem;
  border-radius: 20px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.05);
}`, "",{"version":3,"sources":["webpack://./src/components/card/Card.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B;;;;wBAIsB;AACxB;;AAEA;EACE,0BAA0B;EAC1B,2BAA2B;AAC7B;;AAEA;EACE,kCAA6B;EAA7B,6BAA6B;EAC7B,4BAA4B;EAC5B,6BAA6B;AAC/B;;AAEA;EACE,uBAAuB;AACzB;AACA;EACE,qBAAqB;AACvB;AACA;EACE,uBAAuB;AACzB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,uCAAuC;EACvC,qCAAqC;AACvC","sourcesContent":[".css-1qvr50w-MuiTypography-root {\n  font-size: 11px !important;\n  /* word-wrap: break-word;\n  white-space: nowrap;\n  width: 50px;\n  overflow: scroll;\n  text-overflow: clip; */\n}\n\n.css-nrdprl-MuiTypography-root {\n  font-size: 11px !important;\n  /* word-wrap: break-word; */\n}\n\n.css-o69gx8-MuiCardMedia-root {\n  width: fit-content !important;\n  margin-left: auto !important;\n  margin-right: auto !important;\n}\n\n.css-185gdzj-MuiCardHeader-root {\n  padding: 5px !important;\n}\n.css-185gdzj-MuiCardHeader-root span {\n  word-break: break-all;\n}\n.css-1t6e9jv-MuiCardActions-root {\n  padding: 0px !important;\n}\n.Barcode {\n  padding: 1rem;\n  border-radius: 20px;\n  box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);\n  border: 1px solid rgba(0, 0, 0, 0.05);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
