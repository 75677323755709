import { useRef, useState } from "react";
import MailIcon from "../../assets/images/landing_page/mail-icon.png";
import LocationIcon from "../../assets/images/landing_page/location-icon.png";
import PhoneIcon from "../../assets/images/landing_page/phone-icon.png";
import { sendContactInfo } from "../../services/landing";

export const Contact = () => {
  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const phoneRef = useRef(null);
  const messageRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const res = await sendContactInfo(
      nameRef.current.value,
      emailRef.current.value,
      phoneRef.current.value,
      messageRef.current.value
    );

    if (res === null) {
      setLoading(false);
      alert("Some error occurred, please try again later");
      return;
    }

    if (res === true) {
      setLoading(false);
      e.target.reset();
      alert("Submitted successfully");
      return;
    }
  };

  return (
    <div className="section contact-section" id="contact">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="title-section">
              <h2 className="text-white">Book a Demo to Get Started</h2>
              <p className="text-white m-0">
                Finding the best deals and helping you save effortlessly. We are
                reliable, tech-savvy, and forward-thinking.
              </p>
            </div>
            <div className="contact-info">
              <ul>
                <li>
                  <span className="contact-icon">
                    <img
                      src={MailIcon}
                      className="img-fluid"
                      alt="Quotes Icon"
                    />
                  </span>
                  <span className="contact-text">support@iclipcoupons.com</span>
                </li>
                <li>
                  <span className="contact-icon">
                    <img
                      src={LocationIcon}
                      className="img-fluid"
                      alt="Quotes Icon"
                    />
                  </span>
                  <span className="contact-text">
                    242 Main Street, Ste #102 Beacon, New York 12508
                  </span>
                </li>
                <li>
                  <span className="contact-icon">
                    <img
                      src={PhoneIcon}
                      className="img-fluid"
                      alt="Quotes Icon"
                    />
                  </span>
                  <span className="contact-text">(845) 326-7279</span>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6">
            <form className="message-form" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <input
                      type="text"
                      id="name"
                      name="name"
                      className="form-control"
                      placeholder="Full name"
                      required
                      ref={nameRef}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      type="email"
                      id="email"
                      name="email"
                      className="form-control"
                      placeholder="Email address"
                      required
                      ref={emailRef}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      type="text"
                      id="phone"
                      name="phone"
                      className="form-control"
                      placeholder="Phone number"
                      required
                      ref={phoneRef}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <textarea
                  name="message"
                  id="message"
                  className="form-control"
                  placeholder="I’d like to know more, please contact me."
                  required
                  ref={messageRef}
                ></textarea>
              </div>
              <button
                type="submit"
                className="theme-btn white-btn btn btn-primary"
              >
                {loading ? "Loading..." : "Submit Now"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
