import React, { useState, useEffect } from "react";
import './qrCode.css';
import MainWrapper from "../../components/layout/layout";
import { Navigate, useLoaderData, useNavigate } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Stack from '@mui/material/Stack';
import MailIcon from '@mui/icons-material/Mail';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import Skeleton from '@mui/material/Skeleton';
import Autocomplete from '@mui/material/Autocomplete';
import axios from "axios";
import { Container } from "@mui/system";
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import bwipjs from "bwip-js";
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import { MyQrDocument } from "./QrDoc";
import { pdf } from '@react-pdf/renderer';
import { getImageDimensions, haveNegativeOrDecimal, numericFilter } from "../../utils/utils";
import { imageUpload } from "../../services/image";
import Spinner from 'react-bootstrap/Spinner';
import { ProductDetail } from "../../components/ProductDetail/ProductDetail";
import AddIcon from "@mui/icons-material/Add";
import Tooltip from '@mui/material/Tooltip';
import { getCompanyPrefix } from "../../services/companyPrefix";
import { ProductCoupon } from "../../components/productCoupon/ProductCoupon";
import AddCardIcon from '@mui/icons-material/AddCard';

function QrCode() {
  const loader = useLoaderData()
  const navigate = useNavigate()
  const backendApiUrl = process.env.REACT_APP_BACKEND_API_URL

  const [data, setData] = useState({})
  const [isValueChanging, setIsValueChanging] = useState(true)
  const [companyPrefixes, setCompanyPrefixes] = useState([])
  const [email, setEmail] = useState("")
  const [open, setOpen] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [alertText, setAlertText] = useState("");
  const [src, setImageSrc] = useState(false);
  const [fieldType, setFieldType] = useState("");
  const [couponFunderIdReq, setCouponFunderIdReq] = useState(false);
  const [offerCodeReq, setOfferCodeReq] = useState(false);
  const [SerialReq, setSerialReq] = useState(false);
  const [fileType, setFileType] = useState("png");
  const [imageDimension, setImageDimension] = useState({});
  const [qrType, setQrType] = useState(0);
  const [dataString, setDataString] = useState("");
  const [reportDownloaded, setReportDownloaded] = useState(false);
  const [inpFieldError, setInpFieldError] = useState(false);
  const [imageUploaded, setImageUploaded] = useState(false)
  const [isAdmin, setIsAdmin] = useState(false)

  // check if user is admin or not
  useEffect(() => {
    document.title = "Create Your QR code (This portal is used for digital coupons only)"

    const data = JSON.parse(loader)
    if (data?.email === process.env.REACT_APP_ADMIN_EMAIL) {
      setIsAdmin(true)
      return;
    }
  }, [])

  const generateBarcode = async () => {
    try {
      const string = `(8112)${qrType}${data.couponFunderId.length - 6}${data.couponFunderId}${data.offerCode}${data.serialNumber.length - 6}${data.serialNumber}`

      let canvas = document.createElement("canvas");
      await bwipjs.toCanvas(canvas, {
        bcid: "qrcode", // Barcode type
        text: string,
        scale: 2,
        textsize: 11,
        includetext: true,
        padding: 0,
        textyalign: "above",
        backgroundcolor: "#FFFFFF",
        textxalign: 'left',
      })

      const base64 = canvas.toDataURL("image/png")
      setImageSrc(base64);
      const dimension = await getImageDimensions(base64)
      setImageDimension(dimension)

      setDataString(string.split("(").join("").split(")").join(""))
      setReportDownloaded(false)
      setImageUploaded(false)
      setOpen(false);
      return;

    } catch (error) {
      setImageSrc(false);
      alert(error)
      console.log("barcode error : ", error)
      return;
    }
  }

  const getPrefix = async () => {
    setCompanyPrefixes([])

    var config = {
      method: 'get',
      url: `${backendApiUrl}/admin/view-company`,
    };

    await axios(config)
      .then((response) => {
        response.data.map((d) => [
          setCompanyPrefixes(data => [...data, { "label": d.company_name, "value": d.gs1_company_prefix }])
        ])
        return;
      })
      .catch((error) => {
        console.log("error while getting company prefix: ", error);
        alert("error while getting Company prefix data")
        return;
      });
  }

  const handleChange = (e) => {
    setIsValueChanging(true)
    setData({ ...data, [e.target.name]: e.target.value })

    if (e.target.name === "offerCode" && (e.target.value.length !== 6 || haveNegativeOrDecimal(e.target.value))) {
      setFieldType("offerCode")
      setInpFieldError(true)
      return;
    }
    setFieldType("")

    if (e.target.name === "serialNumber" && (e.target.value.length < 6 || e.target.value.length > 15 || haveNegativeOrDecimal(e.target.value))) {
      setFieldType("serialNumber")
      setInpFieldError(true)
      return;
    }
    setFieldType("")

    setInpFieldError(false)
    return;
  }

  const handleCouponFunderChange = (e, rowValue, reason) => {
    setIsValueChanging(true)

    if (reason === "selectOption") {
      setData({ ...data, "couponFunderId": rowValue.value })
    } else if (reason === "clear") {
      delete data.couponFunderId
    } else {
      return;
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!data.couponFunderId) {
      setCouponFunderIdReq(true)
    } else {
      setCouponFunderIdReq(false)
    }

    if (!data.offerCode) {
      setOfferCodeReq(true)
    } else {
      setOfferCodeReq(false)
    }

    if (!data.serialNumber) {
      setSerialReq(true)
    } else {
      setSerialReq(false)
    }

    if (!data.couponFunderId || !data.offerCode || !data.serialNumber) {
      return;
    }

    if (inpFieldError) {
      return;
    }

    await generateBarcode()
    setIsValueChanging(false)
  }

  const downloadPDF = async () => {


    const blob = await pdf(<MyQrDocument type={qrType} data={data} string={dataString} />).toBlob();
    let a = document.createElement("a");
    document.body.appendChild(a);

    let url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = "QR_report.pdf";
    a.click();
    window.URL.revokeObjectURL(url);
    setReportDownloaded(true)
    return;
  }

  const uploadBarcode = async () => {

    if (imageUploaded) {
      return;
    }

    const data = await imageUpload(src, dataString, "Qrcode")

    if (data === "File saved successfully") {
      setImageUploaded(true)
      setTimeout(() => {
        setOpen(false);
      }, 1500);
      setTimeout(() => {
        setAlertType("success")
        setAlertText("QR code successfully Uploaded")
        setOpen(true);
      }, 3500);
      // clearTimeout(timer);
      return;
    }

    if (data === null) {
      setImageUploaded(false)
      setAlertType("error")
      setAlertText("Some error occurred while uploading Image")
      setOpen(true);
      return;
    }
    console.log("image uploaded: ", data)
  }

  const handleDownload = async () => {
    const downloadLink = document.createElement("a");
    uploadBarcode()

    if (fileType === "png") {
      downloadLink.href = src;
      downloadLink.download = dataString;
      downloadLink.click();
      return;
    }

    if (fileType === "pdf") {
      axios.post(`${backendApiUrl}/manufacturer/base-to-pdf/`, { data_string: src, name_string: dataString }).then((res) => {
        downloadLink.href = res.data.download_link;
        downloadLink.click();
        return;
      }).catch((err) => {
        console.log("error while downloading pdf: ", err)
      })
      return;
    }

    if (fileType === "eps") {
      axios.post(`${backendApiUrl}/manufacturer/base-to-eps/`, { data_string: src, name_string: dataString }).then((res) => {
        downloadLink.href = res.data.download_link;
        downloadLink.click();
        return;
      }).catch((err) => {
        console.log("error in eps: ", err);
        return;
      })
      return;
    }
    return;
  }

  const handleSendMail = async (e) => {
    e.preventDefault();

    var data = JSON.stringify({ "email_add": email, "img_url": src.split(",")[1], file_type: fileType, name_string: dataString, "code_type": "QR code" });

    var config = {
      method: 'post',
      url: `${backendApiUrl}/manufacturer/send/`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    await axios(config)
      .then((response) => {
        setAlertType("success")
        setAlertText("QR code successfully sent.")
        setOpen(true);
        uploadBarcode()
        return;
      })
      .catch((error) => {
        console.log("error while sending mail: ", error);
        setAlertType("error")
        setAlertText("Some error occurred while sending mail")
        setOpen(true);
        return;
      });
    return;
  }

  useEffect(() => {
    return () => getPrefix()
  }, [])

  const onEmailChange = (e) => {
    setEmail(e.target.value)
  }

  const handleFileTypeChange = (e) => {
    setFileType(e.target.value);
  };

  const navigateCreateCoupon = () => {
    navigate("/create-coupon", {
      state: {
        type: "Qrcode",
        src: src,
        dataString: dataString,
        codeData: data,
        expiryDate: null,
        startDate: null,
        OfferDetails: true
      }
    })
  }

  if (loader === null) {
    return (
      <div className="loaderParent">
        <Spinner animation="border" variant="primary" />
        <Navigate to="/login/qrcode" replace={true} />
      </div>
    )
  }

  return (
    <MainWrapper>
      <div id="dpi"></div>
      <div className="content-container">
        <Grid container>
          <Grid item xs={12} md={6} >
            <Box component="form" onSubmit={handleSubmit} sx={{ m: 3, mt: 1 }} >
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">Coupon Format</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  required
                  value={qrType}
                  onChange={e => setQrType(e.target.value)}
                >
                  <FormControlLabel value={0} control={<Radio />} label="Digital" />
                  <FormControlLabel value={1} control={<Radio />} label="Paper" />
                </RadioGroup>
              </FormControl>
              <Box sx={{ display: "flex", alignItems: "flex-end", mt: 2 }}>
                <Autocomplete
                  disablePortal
                  id="couponFunderId"
                  options={companyPrefixes}
                  name="couponFunderId"
                  onChange={handleCouponFunderChange}
                  fullWidth
                  autoSelect={true}
                  loading={companyPrefixes.length === 0 ? true : false}
                  renderInput={(params) => <TextField fullWidth {...params}
                    label="Coupon Funder ID"
                    autoFocus={true}
                    error={couponFunderIdReq}
                    helperText={couponFunderIdReq && "Coupon funder ID is required"}
                  />}
                />
                <Tooltip className={isAdmin ? "" : "hide"} title="Add Coupon Funder ID" sx={{ ml: 2, my: "auto" }} onClick={() => navigate("/companyprefixes/login")} >
                  <IconButton>
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              </Box>
              <TextField sx={{ mt: 2 }} id="offerCode" name="offerCode" type="number" label="Offer Code" variant="outlined" fullWidth
                value={data.offerCode || ""} onKeyDown={numericFilter}
                onChange={handleChange}
                placeholder="Should be 6 digits"
                error={fieldType === "offerCode" || offerCodeReq}
                helperText={(fieldType === "offerCode" || offerCodeReq) && "Offer Code is required and should be 6 digits"}
              />
              <TextField sx={{ mt: 2 }} id="serialNumber" name="serialNumber" type="number" label="Serial Number" variant="outlined" fullWidth
                value={data.serialNumber || ""} onKeyDown={numericFilter}
                onChange={handleChange}
                placeholder="Should be 6 to 15 digits"
                error={fieldType === "serialNumber" || SerialReq}
                helperText={(fieldType === "serialNumber" || SerialReq) && "Serial Number is required and should be 6 to 15 digits"}
              />
              <Button className="customBtn" sx={{ mt: 4 }} size="" fullWidth type="submit" variant="contained" endIcon={<ChevronRightIcon />}>
                Generate
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} >
            <Collapse sx={{ mr: 1 }} in={open}>
              <Alert
                severity={alertType}
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                sx={{ mb: 2 }}
              >
                {alertText}
              </Alert>
            </Collapse>
            <Box sx={{}} >
              <Typography sx={{ mt: 1, ml: 5 }} component="h3" variant="h5">
                <strong>Generated QR Code</strong>
              </Typography>
              <Typography sx={{ ml: 5 }} component="h6" variant="h6">QR code string:  <code className="barcodeString">{`(8112)${qrType}${data.couponFunderId ? data.couponFunderId.length - 6 : ""}${data.couponFunderId ? data.couponFunderId : ''}${data.offerCode ? data.offerCode : ''}${data.serialNumber ? data.serialNumber.length - 6 : ''}${data.serialNumber ? data.serialNumber : ''}`}</code></Typography>
              <Stack sx={{ mt: 3, ml: 2 }}>
                <Container sx={isValueChanging ? { display: 'block' } : { display: 'none' }}>
                  <Skeleton variant="rectangular" width={120} height={120} />
                </Container>
                <Container sx={isValueChanging ? { display: 'none' } : { display: 'block' }}>
                  <img id="barcodeImage" src={src} alt="barcode" />
                </Container>
              </Stack>
              <Stack mt={1} ml={4} sx={isValueChanging || src === false ? { display: 'none' } : { display: 'block' }} >
                <p>{`Size: ${Object.keys(imageDimension).length === 0 ? '' : `${(imageDimension.w / document.getElementById("dpi")?.offsetHeight).toFixed(2)}” x ${(imageDimension.h / document.getElementById("dpi")?.offsetHeight).toFixed(2)}” `}`}</p>
              </Stack>
            </Box>
            <Box onSubmit={(e) => handleSendMail(e)} component="form" sx={{ m: 5 }} >
              <TextField id="email" name="email" type="email" label="Enter email to send QR code" variant="outlined" required fullWidth
                value={email}
                onChange={onEmailChange} />
              <Grid container sx={{ mt: 5 }} direction="row" justifyContent="space-between" alignItems="center" >
                <Grid item lg={2} md={12} xs={12} >
                  <FormControl sx={{ minWidth: 80 }} size="small">
                    <InputLabel id="fileType">File type</InputLabel>
                    <Select
                      labelId="fileType"
                      id="fileTypeId"
                      value={fileType}
                      label="File type"
                      onChange={handleFileTypeChange}
                    >
                      <MenuItem value="png">PNG</MenuItem>
                      <MenuItem value="pdf">PDF</MenuItem>
                      <MenuItem value="eps">EPS</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item lg={10} md={12} xs={12} direction="row-reverse" justifyContent="flex-end" alignItems="center" >
                  <Grid container justifyContent="flex-end" alignItems="center" >
                    <Button className={isValueChanging || src === false ? "customBtn-disabled" : "customBtnsm"} sx={{ mr: 1 }} disabled={isValueChanging || src === false ? true : false} type="submit" variant="contained" startIcon={<MailIcon />}>
                      Mail
                    </Button>
                    <Button className={isValueChanging || src === false ? "customBtn-disabled" : "customBtnsm"} sx={{ mr: 1 }} disabled={isValueChanging || src === false ? true : false} onClick={() => handleDownload()} variant="contained" endIcon={<SaveAltIcon />}>
                      Download
                    </Button>
                    <Button className={isValueChanging || src === false ? "customBtn-disabled" : "customBtnsm"} disabled={isValueChanging || src === false ? true : false} onClick={() => downloadPDF()} variant="contained" endIcon={<SaveAltIcon />}>
                      Report
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Box>

            <Box sx={{ m: 5 }} >
              <Button sx={{ height: "50px" }} fullWidth className={isValueChanging || src === false ? "customBtn-disabled" : "customBtnsm"} disabled={isValueChanging || src === false ? true : false} onClick={() => navigateCreateCoupon()} variant="contained" endIcon={<AddCardIcon />}>
                Create Coupon
              </Button>

            </Box>
            {/* <ProductDetail type="Qrcode" isValueChanging={isValueChanging} src={src} dataString={dataString} codeData={data} expiryDate={null} /> */}
            {/* <ProductCoupon type="Qrcode" isValueChanging={isValueChanging} src={src} dataString={dataString} codeData={data} expiryDate={null} startDate={null} /> */}
          </Grid>
        </Grid>
      </div>
    </MainWrapper>
  );
}

export default QrCode;
