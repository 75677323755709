import { useState, useEffect } from "react";
import { useNavigate, useLoaderData } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { MuiFileInput } from "mui-file-input";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { getProductCategory } from "../../services/productCategory";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import { createCoupon } from "../../services/coupon";
import { toast, ToastContainer } from "react-toastify";
import { Carousel } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";

export const ProductDetail = ({
  type,
  isValueChanging,
  src,
  dataString,
  codeData,
  expiryDate,
}) => {
  const navigate = useNavigate();
  const data = JSON.parse(useLoaderData());

  const [productImage, setProductImage] = useState([]);
  const [uploadImage, setUploadImage] = useState([]);
  const [productCategories, setProductCategories] = useState([]);
  const [productData, setProductData] = useState({});
  const [imageError, setImageError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [categoryError, setCategoryError] = useState(false);
  const [shortDescError, setShortDescError] = useState(false);
  const [descError, setDescError] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (data?.email === process.env.REACT_APP_ADMIN_EMAIL) {
      setIsAdmin(true);
      return;
    }
  }, []);

  useEffect(() => {
    return async () => {
      setProductCategories([]);
      const data = await getProductCategory();
      data?.map((d) => [
        setProductCategories((data) => [
          ...data,
          { label: d.product_category_name, value: d.product_category_name },
        ]),
      ]);
    };
  }, []);

  const convertToBase64 = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      setProductImage((items) => [...items, reader.result]);
    };
  };

  const handleProductImage = (value) => {
    console.log("product image: ", value);

    setUploadImage(value);

    if (value.length === 0) {
      setProductImage([]);
      return;
    }

    value.map((image) => {
      convertToBase64(image);
    });

    // setProductImage(value);
    return;
  };

  const handleProductChange = (e) => {
    setProductData({ ...productData, [e.target.name]: e.target.value });
  };

  const handleCategoryChange = (e, rowValue, reason) => {
    if (reason === "selectOption") {
      setProductData({ ...productData, category: rowValue.value });
    } else {
      return;
    }
  };

  const handleProductSubmit = async (e) => {
    e.preventDefault();

    if (productImage.length === 0) {
      setImageError(true);
    } else {
      setImageError(false);
    }

    if (!productData.name) {
      setNameError(true);
    } else {
      setNameError(false);
    }

    if (!productData.category) {
      setCategoryError(true);
    } else {
      setCategoryError(false);
    }

    if (!productData.shortDesc) {
      setShortDescError(true);
    } else {
      setShortDescError(false);
    }

    if (!productData.desc) {
      setDescError(true);
    } else {
      setDescError(false);
    }

    if (
      productImage.length === 0 ||
      !productData.name ||
      !productData.desc ||
      !productData.shortDesc ||
      !productData.category
    ) {
      return;
    }

    setLoading(true);
    const res = await createCoupon(
      data.email,
      dataString,
      type,
      src,
      productData.name,
      productData.category,
      productData.shortDesc,
      productData.desc,
      expiryDate ? expiryDate : null,
      codeData.saveValue
        ? codeData.saveValue.substring(0, 1) +
            "." +
            codeData.saveValue.substring(1)
        : null,
      productImage
    );

    if (res === null) {
      setLoading(false);
      toast.error("Unable to create coupon, please try later.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    setLoading(false);
    toast.success("Coupon created successfully!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    return;
  };

  return (
    <Box onSubmit={handleProductSubmit} component="form" sx={{ m: 5 }}>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        containerId="notification"
      />
      <ToastContainer />
      <Typography sx={{ mb: 4 }} component="h1" variant="h5">
        <strong>Upload Product Details</strong>
      </Typography>
      {/* {productImage.length > 0 && (
        <Carousel className="mb-5" data-bs-theme="dark">
          {productImage.map((item, key) => (
            <Carousel.Item key={key}>
              <img
                className="product-Image"
                src={item}
                alt="product-image"
                loading="lazy"
              />
            </Carousel.Item>
          ))}
        </Carousel>
      )} */}
      {productImage.length > 0 && (
        <ImageList
          sx={{ width: 520, height: 166, mb: 3 }}
          cols={3}
          rowHeight={164}
        >
          {productImage.map((item, key) => (
            <ImageListItem key={key}>
              <img
                className="productImage"
                src={item}
                alt="product-image"
                loading="lazy"
              />
            </ImageListItem>
          ))}
        </ImageList>
      )}
      <div>
        <MuiFileInput
          className="fileInput"
          multiple
          value={uploadImage}
          placeholder="Upload Product Images"
          onChange={handleProductImage}
          InputProps={{
            inputProps: {
              accept: "image/*",
            },
            startAdornment: <AddPhotoAlternateIcon />,
          }}
          clearIconButtonProps={{
            title: "Remove",
            children: <CloseIcon fontSize="small" />,
          }}
          error={imageError}
          helperText={imageError && "Product Images is required"}
        />
      </div>
      <TextField
        sx={{ mt: 2 }}
        name="name"
        type="text"
        label="Product Name"
        variant="outlined"
        fullWidth
        value={productData.name || ""}
        onChange={handleProductChange}
        error={nameError}
        helperText={nameError && "Product Name is required"}
      />
      <Box sx={{ display: "flex", alignItems: "flex-end", mt: 2 }}>
        <Autocomplete
          // sx={{ mt: 2 }}
          disablePortal
          options={productCategories}
          name="category"
          onChange={handleCategoryChange}
          fullWidth
          autoSelect={true}
          loading={productCategories.length === 0 ? true : false}
          renderInput={(params) => (
            <TextField
              fullWidth
              {...params}
              label="Product Category"
              error={categoryError}
              helperText={categoryError && "Product Category is required"}
            />
          )}
        />
        <Tooltip
          className={isAdmin ? "" : "hide"}
          title="Add product category"
          sx={{ ml: 2, my: "auto" }}
          onClick={() => navigate("/productcategory/login")}
        >
          <IconButton>
            <AddIcon />
          </IconButton>
        </Tooltip>
      </Box>

      <TextField
        sx={{ mt: 2 }}
        name="shortDesc"
        type="text"
        label="Product Short description"
        variant="outlined"
        fullWidth
        value={productData.shortDesc || ""}
        onChange={handleProductChange}
        error={shortDescError}
        helperText={shortDescError && "Short description is required"}
      />
      <TextField
        sx={{ mt: 2 }}
        name="desc"
        type="text"
        label="Fine Legal Text"
        variant="outlined"
        multiline
        rows={5}
        fullWidth
        value={productData.desc || ""}
        onChange={handleProductChange}
        error={descError}
        helperText={descError && "Product description is required"}
      />

      <Button
        className={
          isValueChanging || src === false ? "defaultBtn" : "customBtn"
        }
        sx={{ mt: 2 }}
        fullWidth
        type="submit"
        variant="contained"
        startIcon={<ConfirmationNumberIcon />}
        disabled={isValueChanging || src === false ? true : false}
      >
        {loading ? "Loading..." : "Create Coupon"}
      </Button>
    </Box>
  );
};
