import React from 'react';
import { Document, Page, Text, View, StyleSheet, Font } from '@react-pdf/renderer';
import { TableRow } from '../../components/TableRow';
import font from "../../assets/fonts/Arimo-Bold.ttf"

Font.register({
    family: "arial",
    src: font
});


// Create Document Component
export const MyDocument = ({ data, string }) => {


    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.sectionHeading}>
                    <Text style={styles.heading} >Coupon Validator Report</Text>
                </View>
                <View style={styles.section}>
                    <Text style={styles.text}>{`Offer: ${data?.offerCode} - SAVE $${data?.saveValue?.substring(0, 1) + "." + data?.saveValue?.substring(1)} OFF ONE ITEM`}</Text>
                    <Text style={styles.text}>No Value Code or UPCA</Text>
                    <Text style={styles.text}>Final Standard Coupon</Text>
                    <Text style={styles.text}>{`Current Symbol Decode: ${string} (${string.length} digits)`}</Text>
                    <Text style={styles.text}>{`Coupon Interpretation: Save $${data?.saveValue?.substring(0, 1) + "." + data?.saveValue?.substring(1)} with the purchase of ${data?.primaryPurchaseRequirement} item(s) of family code ${data?.primaryPurchaseFamilyCode} with Primary GS1 Company Prefix ${data?.companyPrefix}.`}</Text>
                </View>
                <View style={styles.sectionTable}>
                    <TableRow first={"Primary GS1 Company Prefix"} second={data?.companyPrefix} />
                    <TableRow first={"Offer Code"} second={data?.offerCode} />
                    <TableRow first={"Value Code"} second={"--"} third={"--"} />
                    <TableRow first={"Primary Purchase Req."} second={data?.primaryPurchaseRequirement} />
                    <TableRow first={"Primary Purchase Req. Code"} second={data?.primaryPurchaseRequirementCode} third={"Threshold number of units to purchase"} />
                    <TableRow first={"Primary Purchase Family Code"} second={data?.primaryPurchaseFamilyCode} />
                    <TableRow first={"Start Date (YYMMDD)"} second={data?.startDate} />
                    <TableRow first={"Expiration Date (YYMMDD)"} second={data?.expiryDate} />
                    <TableRow first={"Serial Number"} second={data?.serialNumber} />
                    <TableRow first={"Save Value"} second={data?.saveValue} third={`Save $${data?.saveValue?.substring(0, 1) + "." + data?.saveValue?.substring(1)} off one item`} />
                    <TableRow first={"Additional Purchase Rules Code"} second={data?.additionalPurchaseRuleCode} />
                    <TableRow first={"Second Purchase Requirement"} second={data?.secondPurchaseRequirement} />
                    <TableRow first={"Second Purchase Requirement Code"} second={data?.secondPurchaseRequirementCode} />
                    <TableRow first={"Second Purchase Family Code"} second={data?.secondPurchaseFamilyCode} />
                    <TableRow first={"Second Company Prefix"} second={data?.secondCompanyPrefix} />
                    <TableRow first={"Third Purchase Requirement"} />
                    <TableRow first={"Third Purchase Requirement Code"} />
                    <TableRow first={"Third Purchase Family Code"} />
                    <TableRow first={"Third Company Prefix"} />
                    <TableRow first={"Retailer Company Prefix"} second={data?.retailerCompanyPrefix} />
                    <TableRow first={"Save Value Code"} second={data?.saveValueCode} />
                    <TableRow first={"Save Value Item"} second={data?.saveValueAppliesItem} />
                    <TableRow first={"Store Coupon Flag"} second={data?.storeCoupon} />
                    <View style={styles.lastTableRow}>
                        <View style={styles.firstRowItem}>
                            <Text style={styles.tableText}>Do Not Multiply Flag</Text>
                        </View>
                        <View style={styles.secRowItem}>
                            <Text style={styles.tableText}>{data?.multiplyFlag}</Text>
                        </View>
                        <View style={styles.thirdRowItem}>
                            <Text style={styles.tableText}></Text>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    )
}

// Create styles
const styles = StyleSheet.create({
    page: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#fff',
        padding: '25px',
        fontFamily: 'arial',
    },
    sectionHeading: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '30px',
    },
    heading: {
        fontSize: '14px',
    },
    section: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        marginBottom: '30px',
    },
    text: {
        fontSize: '9px',
        marginTop: "5px",
    },
    sectionTable: {
        display: 'flex',
        flexDirection: 'column',
    },
    lastTableRow: {
        display: 'flex',
        flexDirection: 'row',
        borderWidth: '1.5px',
        borderColor: 'black',
        borderStyle: 'solid',
    },
    firstRowItem: {
        width: '35%',
        padding: '1px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        borderRightWidth: '1px',
        borderRightColor: 'black',
        borderRightStyle: 'solid',
    },
    secRowItem: {
        width: '25%',
        padding: '1px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        borderRightWidth: '1px',
        borderRightColor: 'black',
        borderRightStyle: 'solid',
    },
    thirdRowItem: {
        width: '40%',
        padding: '1px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    borderBottom: {

        borderBottomWidth: '1px',
        borderBottomColor: 'black',
        borderBottomStyle: 'solid',
    },
    tableText: {
        fontSize: '9px',
        marginLeft: '3px'
    },

});