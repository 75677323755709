import React from "react";
import IconButton from "@mui/material/IconButton";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import DeleteIcon from "@mui/icons-material/Delete";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

export const Manufacturer = ({ data, handleManufacturerDelete }) => {
  const navigate = useNavigate();
  const { user_email, user_name, user_profile_pic_url} = data;

  const handleNavigation = () => {
    navigate(`/manufacturer-coupon/${user_email}`);
    return;
  };
  return (
    <div className="manufacturer" sx={{ maxWidth: 450 }}>
      <CardHeader
        sx={{ mt: 0.5 }}
        avatar={
         <Avatar
              src={user_profile_pic_url}
              alt="User Avatar"
              sx={{ width: 36, height: 36 }}
              variant="circular"
            />
        }
        title={user_name} 
        subheader={user_email}
      />
      <CardActions sx={{ mt: 1 }}>
        <Button
          className="customBtnsm"
          variant="contained"
          size="small"
          onClick={handleNavigation}
        >
          Show coupons
        </Button>
        <Button variant="outlined" color="error" aria-label="settings" onClick={() => handleManufacturerDelete(user_email)}>
            Delete
          </Button>
      </CardActions>
    </div>
  );
};
