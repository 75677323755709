import React from "react";
import Button from "react-bootstrap/Button";
import HeroImg from "../../assets/images/landing_page/hero-img.png";
import CheckIcon from "../../assets/images/landing_page/check-icon.png";
import FilterIcon from "../../assets/images/landing_page/filter-icon.png";
import SalesIcon from "../../assets/images/landing_page/sales-icon.png";

export const Hero = () => {
  return (
    <div className="section position-relative" id="hero">
      <div className="container position-relative">
        <div className="bg-shadow"></div>
        <div className="row hero-row">
          <div className="col-xs-12 col-lg-6">
            <div className="hero-text">
              <div className="title-section">
                <h3>Revolutionize the coupon industry</h3>
                <h2>
                  Revolutionize your shopping experience with our{" "}
                  <span>innovative software</span>
                </h2>
              </div>
              <p>
                Our platform offers a one-stop solution for manufacturers to
                launch and distribute coupons, while consumers can easily
                browse, add, and save on the items they need.
              </p>
              <Button href="#contact" className="theme-btn">
                Contact Us
              </Button>
            </div>
          </div>
          <div className="col-xs-12 col-lg-6">
            <div className="hero-img-area position-relative">
              <div className="feature-block hero-feature1 position-absolute">
                <img src={CheckIcon} className="img-fluid" alt="Check Icon" />
                <span>Save money on purchases</span>
              </div>
              <img
                src={HeroImg}
                className="img-fluid main-img"
                alt="Hero Image"
              />
              <div className="feature-block hero-feature2 position-absolute">
                <img src={FilterIcon} className="img-fluid" alt="Check Icon" />
                <span>Sales conversion rate</span>
              </div>
              <div className="feature-block hero-feature3 position-absolute">
                <img src={SalesIcon} className="img-fluid" alt="Check Icon" />
                <span>Increase product sales</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
