import React, { useState } from "react";
import './auth.css'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from '@mui/material/Button';
import Logo from "../../assets/images/landing_page/logo.png";
import LockIcon from '../../assets/images/lock.svg';
import EmailIcon from '../../assets/images/mail.svg';
import { Auth } from 'aws-amplify';
import Grid from '@mui/material/Grid';
import { Link, useNavigate } from "react-router-dom";
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

function ResetPassword() {
    const [email, setEmail] = useState(null)
    const [code, setCode] = useState(null)
    const [password, setPassword] = useState(null)
    const [loading, setLoading] = useState(false)
    const [passwordError, setPasswordError] = useState(false);
    const [open, setOpen] = useState(false);
    const [errorText, setErrorText] = useState("")

    const navigate = useNavigate()

    const handlePasswordChange = (data) => {
        setPassword(data)

        let regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.#$!%*?&^])[A-Za-z\d@.#$!%*?&]{8,15}$/;
        if (!regex.test(data)) {
            setPasswordError(true)
            return;
        }

        setPasswordError(false)
        return;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (passwordError) {
            return;
        }
        setLoading(true)

        try {
            const data = await Auth.forgotPasswordSubmit(email, code, password);
            navigate("/login/barcode")
            setLoading(false)
            return;
        } catch (err) {
            console.log(err);
            setLoading(false)

            if (err.name === "ExpiredCodeException") {
                setErrorText("Invalid code provided, please request a code again.")
                setOpen(true)
                return;
            }

            if (err.name === "UserNotFoundException") {
                setErrorText("User does not exist.")
                setOpen(true)
                return;
            }

            if (err.name === "CodeMismatchException") {
                setErrorText("Invalid verification code provided, please try again.")
                setOpen(true)
                return;
            }

            setErrorText("Something went wrong, please try again later.")
            setOpen(true)

            return;
        }
    };

    return (
        <section className="full-page-layout">
            <Container fluid>
                <Row className="justify-content-center">
                    <Col xs={12} md={4} className="bg-white">
                        <div className="login-area full-height">
                            <div className="form-area">
                                <Collapse in={open}>
                                    <Alert severity="error"
                                        action={
                                            <IconButton
                                                aria-label="close"
                                                color="inherit"
                                                size="small"
                                                onClick={() => {
                                                    setOpen(false);
                                                }}
                                            >
                                                <CloseIcon fontSize="inherit" />
                                            </IconButton>
                                        }
                                        sx={{ mb: 2 }}
                                    >
                                        {errorText}
                                    </Alert>
                                </Collapse>
                                <div className="text-center mb-4">
                                    <img className="authLogo" onClick={() => navigate("/")} src={Logo} alt="iClipCoupons" />
                                </div>
                                <h3 className="mb-4 auth-title">Create new password</h3>
                                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="email"
                                        type="email"
                                        label="Email"
                                        name="email"
                                        autoFocus={true}
                                        autoComplete="email" value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="code"
                                        label="Verification Code"
                                        type="text"
                                        id="code"
                                        value={code}
                                        onChange={(e) => setCode(e.target.value)}
                                        helperText="Verification code sent to your mail."
                                    />
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="password"
                                        label="Password"
                                        type="password"
                                        id="password"
                                        value={password}
                                        onChange={(e) => handlePasswordChange(e.target.value)}
                                        error={passwordError}
                                        helperText={passwordError && "Password should have at least 1 number, 1 special character, 1 uppercase, 1 lowercase letter and in between 8 - 15 characters"}
                                    />
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        sx={{ mt: 3, mb: 2 }}
                                    >
                                        {loading ? "loading..." : "Submit"}
                                    </Button>
                                    <Grid container>
                                        <Grid item>
                                            <Link to="/forgot-password" className="auth-link">
                                                {"Didn't get the Code? Resend"}
                                            </Link>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default ResetPassword;
