import axios from "axios";
const URL = process.env.REACT_APP_BACKEND_API_URL

export const getCompanyPrefix = () => {
    var config = {
        method: 'get',
        url: `${URL}/admin/view-company`,
    };

    const res = axios(config)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log("error while getting company prefix: ", error);
            alert("error while getting Company prefix data")
            return null;
        });

    return res;
}

export const deleteCompanyPrefix = (id) => {
    let config = {
        method: 'delete',
        url: `${URL}/admin/delete-company/${id}/`,
    };

    const res = axios.request(config)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log("error in delete company prefix: ", error);
            return null;
        });

    return res;
}

export const addCompanyPrefix = (data) => {
    let apiData = JSON.stringify({ "company_name": data.name, "gs1_company_prefix": data.prefix });

    let config = {
        method: 'post',
        url: `${URL}/admin/add-company`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: apiData
    };

    const res = axios(config)
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log("error in add prefix: ", err);
            alert("something went wrong, please try later");
            return null;
        });

    return res;
}

export const updateCompanyPrefix = (data, id) => {
    let configData = JSON.stringify({
        "company_name": data.name,
        "gs1_company_prefix": data.prefix
    });

    let config = {
        method: 'put',
        url: `${URL}/admin/update-company/${id}/`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: configData
    };

    const res = axios.request(config)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log("error in update prefix: ", error);
            return null;
        });
    return res;
}