import React, { useState, useEffect } from "react";
import Logo from "../../assets/images/landing_page/logo.png";
import { useNavigate } from "react-router-dom";

export const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  const handleLinkClick = (link) => {
    closeMenu();
  };

  return (
    <nav id="menu" className="navbar navbar-expand-md">
      <div className="container">
        <div className="d-flex align-items-center justify-content-between w-100">
          <a className="navbar-brand p-0">
            <img src={Logo} className="img-fluid" alt="Logo" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            onClick={handleToggle}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className={`collapse navbar-collapse menu-links ${
              isOpen ? "show" : ""
            }`}
            id="navbarScroll"
          >
            <ul className="navbar-nav me-auto navbar-nav-scroll">
              <li className="nav-item">
                <a
                  className="nav-link"
                  // href="/"
                  // onClick={() => handleLinkClick("hero")}
                >
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  // href="#features"
                  onClick={() => navigate("/coupons/login")}
                >
                  Coupons
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  // href="#benefits"
                  onClick={() => navigate("/barcode/login")}
                >
                  Services
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="#contact"
                  onClick={() => handleLinkClick("contact")}
                >
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};
