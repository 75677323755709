// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hr {
  margin: 0;
  padding: 0;
}
.nav-tabs {
  border-bottom: 0;
  margin-bottom: 20px;
}
.tabs {
  padding: 10px;
  margin-left: 0px;
  border-bottom: 0;
  margin-right: 0px;
}
.tab {
  padding: 10px;
  /*border-top: 1px solid #ccc;*/
}
.nav-tabs .nav-link {
  color: #0032a1;
  border-radius: 20px;
  margin-right: 0.5rem;
}
.nav-tabs .nav-item.show .nav-link, 
.nav-tabs .nav-link.active {
  color: #FFF;
  border-width: 1;
  background: #0032a1;
  border-color: #0032a1;
}
.loaderParent {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.couponsContainer {
  gap: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.tab-content {
  padding: 10px;
  border-radius: 30px;
  border: #e4e4e4 solid 1px;
}
@media only screen and ( min-width: 992px ) {
  .tabs {
    margin-left: 40px;
    margin-right: 40px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/coupons/Coupons.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;AACZ;AACA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,gBAAgB;EAChB,gBAAgB;EAChB,iBAAiB;AACnB;AACA;EACE,aAAa;EACb,8BAA8B;AAChC;AACA;EACE,cAAc;EACd,mBAAmB;EACnB,oBAAoB;AACtB;AACA;;EAEE,WAAW;EACX,eAAe;EACf,mBAAmB;EACnB,qBAAqB;AACvB;AACA;EACE,WAAW;EACX,aAAa;EACb,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,SAAS;EACT,aAAa;EACb,eAAe;EACf,2BAA2B;AAC7B;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,yBAAyB;AAC3B;AACA;EACE;IACE,iBAAiB;IACjB,kBAAkB;EACpB;AACF","sourcesContent":[".hr {\n  margin: 0;\n  padding: 0;\n}\n.nav-tabs {\n  border-bottom: 0;\n  margin-bottom: 20px;\n}\n.tabs {\n  padding: 10px;\n  margin-left: 0px;\n  border-bottom: 0;\n  margin-right: 0px;\n}\n.tab {\n  padding: 10px;\n  /*border-top: 1px solid #ccc;*/\n}\n.nav-tabs .nav-link {\n  color: #0032a1;\n  border-radius: 20px;\n  margin-right: 0.5rem;\n}\n.nav-tabs .nav-item.show .nav-link, \n.nav-tabs .nav-link.active {\n  color: #FFF;\n  border-width: 1;\n  background: #0032a1;\n  border-color: #0032a1;\n}\n.loaderParent {\n  width: 100%;\n  height: 100vh;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.couponsContainer {\n  gap: 10px;\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: flex-start;\n}\n.tab-content {\n  padding: 10px;\n  border-radius: 30px;\n  border: #e4e4e4 solid 1px;\n}\n@media only screen and ( min-width: 992px ) {\n  .tabs {\n    margin-left: 40px;\n    margin-right: 40px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
