import React, { useState, useEffect } from "react";
// import "./Profile.css";
import MainWrapper from "../../components/layout/layout";
import {
  Navigate,
  useLoaderData,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Logout from "@mui/icons-material/Logout";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import NavigateNextOutlinedIcon from "@mui/icons-material/NavigateNextOutlined";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import Spinner from "react-bootstrap/Spinner";
import { UpdateUser } from "../../components/profile/updateUser/UpdateUser";
import { getUser } from "../../services/user";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import defaultImage from "../../assets/images/blank-profile-picture-973460_1280.webp";
import { Row, Col, Button } from "react-bootstrap";
import { ProductCoupon } from "../../components/productCoupon/ProductCoupon";

export const CreateCoupon = () => {
  const loader = useLoaderData();
  const user = JSON.parse(loader);
  const navigate = useNavigate();
  const state = useLocation().state;
  console.log("state data: ", state);
  const { type, src, dataString, codeData, startDate, expiryDate, OfferDetails } = state;

  useEffect(() => {
    document.title = "Create Coupon";
  }, []);

  //   if (loader === null) {
  //     return (
  //       <div className="loaderParent">
  //         <Spinner animation="border" variant="primary" />
  //         <Navigate to="/login/profile" replace={true} />
  //       </div>
  //     );
  //   }

  return (
    <MainWrapper>
      <div className="content-container">
        <Row>
          <Col md={12}>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
              containerId="createCouponNotification"
            />
            <ToastContainer />

            <ProductCoupon
              type={type}
              src={src}
              dataString={dataString}
              codeData={codeData}
              expiryDate={expiryDate}
              startDate={startDate}
              OfferDetails={OfferDetails}
            />
          </Col>
        </Row>
      </div>
    </MainWrapper>
  );
};
