import React from 'react'
import { Text, View, StyleSheet } from '@react-pdf/renderer';

export const TableRow = ({ first, second, third }) => {
    return (
        <View style={styles.tableRow}>
            <View style={styles.firstRowItem}>
                <Text style={styles.tableText}>{first}</Text>
            </View>
            <View style={styles.secRowItem}>
                <Text style={styles.tableText}>{second ? second : ''}</Text>
            </View>
            <View style={styles.thirdRowItem}>
                <Text style={styles.tableText}>{third ? third : ""}</Text>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    tableRow: {
        display: 'flex',
        flexDirection: 'row',
        borderWidth: '1.5px',
        borderColor: 'black',
        borderStyle: 'solid',
        borderBottomWidth: '0px',
    },
    firstRowItem: {
        width: '35%',
        padding: '1px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        borderRightWidth: '1px',
        borderRightColor: 'black',
        borderRightStyle: 'solid',
    },
    secRowItem: {
        width: '25%',
        padding: '1px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        borderRightWidth: '1px',
        borderRightColor: 'black',
        borderRightStyle: 'solid',
    },
    thirdRowItem: {
        width: '40%',
        padding: '1px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    tableText: {
        fontSize: '9px',
        fontWeight: 'bold',
        marginLeft: '3px'
    },
})
