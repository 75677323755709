import axios from "axios";
const URL = process.env.REACT_APP_BACKEND_API_URL

export const getManufacturers = () => {
    const config = {
        method: "GET",
        url: `${URL}/admin/list-Manufacturers/`
    }

    const res = axios(config).then((res) => {
        return res.data;
    }).catch((err) => {
        console.log("error in getManufacturers: ", err);
        return null;
    })

    return res;
}

export const deleteManufacturer = (email) => {
    const data = JSON.stringify({ "manufacturer_email": email });

    const config = {
        method: "DELETE",
        url: `${URL}/admin/delete-manufacturer/`,
        headers: {
            "Content-Type": "application/json"
        },
        data: data,
    }

    const res = axios(config).then((res) => {
        return res.data;
    }).catch((err) => {
        console.log("error deleting manufacturer: ", err);
        return null;
    })

    return res;
}