import React, { useState } from "react";
import "./auth.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Logo from "../../assets/images/landing_page/logo.png";
import { Auth } from "aws-amplify";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Navigate, Link, useNavigate } from "react-router-dom";
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import { addUser } from "../../services/user";

function Register() {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingOtp, setLoadingOtp] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [passwordError, setPasswordError] = useState(false);

  const [open, setOpen] = useState(false);
  const [alertText, setAlertText] = useState("");

  const navigate = useNavigate()
  const handleClose = () => setShow(false);

  const handlePasswordChange = (data) => {
    setPassword(data)

    let regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.#$!%*?&^])[A-Za-z\d@.#$!%*?&]{8,15}$/;
    if (!regex.test(data)) {
      setPasswordError(true)
      return;
    }

    setPasswordError(false)
    return;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (passwordError) {
      return;
    }

    setLoading(true)
    setOpen(false)
    try {
      await Auth.signUp({
        username: email,
        password,
        attributes: {
          email: email,
          preferred_username: username,
        },
      });

      setLoading(false)
      setShow(true);
      return;
    } catch (error) {
      console.log("error sign up :", error);
      setLoading(false)
      if (error.name === "UsernameExistsException") {
        setAlertText("An account with the given email already exists.")
        setOpen(true)
        return;
      }

      setAlertText("Something went wrong, please try again later.")
      setOpen(true)
      return;
    }
  };

  const handleConfirm = async (e) => {
    e.preventDefault();

    setLoadingOtp(true)
    try {
      const otpData = await Auth.confirmSignUp(email, otp);

      const res = await addUser(username, email)

      if (res === null) {
        setLoadingOtp(false)
        setAlertText("Something went wrong, please try again later.")
        setOpen(true)
        return;
      }

      setLoadingOtp(false)
      setShow(false);
      setIsRegistered(true)
    } catch (error) {
      console.log("error confirming sign up", error);
      setLoadingOtp(false)

      if (error.name === "CodeMismatchException") {
        setAlertText("Invalid verification code provided, please try again.")
        setOpen(true)
        return;
      }

      setAlertText("Something went wrong, please try again later.")
      setOpen(true)
      return;
    }
  };

  return (
    <section className="full-page-layout">
      <Container fluid className="p-md-0">
        {isRegistered && (
          <Navigate to="/login/barcode" replace={true} />
        )}
        <Row className="m-md-0 justify-content-center">
          <Col xs={12} md={4} className="bg-white p-md-0">
            <div className="full-height">

              <div className="login-area w-100">
                <div className="form-area">
                  <Collapse sx={{ mr: 1 }} in={open}>
                    <Alert
                      severity="error"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setOpen(false);
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                      sx={{ mb: 2 }}
                    >
                      {alertText}
                    </Alert>
                  </Collapse>
                  <div className="text-center mb-4">
                    <img className="authLogo" onClick={() => navigate("/")} src={Logo} alt="iClipCoupons" />
                  </div>
                  <h3 className="mb-4 auth-title">Create your account</h3>
                  <Box component="form" onSubmit={(e) => handleSubmit(e)} sx={{ mt: 1 }}>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="username"
                      label="User name"
                      name="username"
                      autoComplete="username"
                      type="text"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      autoFocus
                    />
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      type="email"
                      autoComplete="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      value={password}
                      onChange={(e) => handlePasswordChange(e.target.value)}
                      error={passwordError}
                      helperText={passwordError && "Password should have at least 1 number, 1 special character, 1 uppercase, 1 lowercase letter and in between 8 - 15 characters"}
                    />
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                    >
                      {loading ? "loading..." : "Submit"}
                    </Button>
                    <Grid container>
                      <Grid item>
                        <Link to="/login/barcode" className="auth-link">
                          {"Already have an account? Sign In"}
                        </Link>
                      </Grid>
                    </Grid>
                  </Box>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Dialog open={show} >
        <DialogTitle>Confirm Registration</DialogTitle>
        <Box component="form" onSubmit={(e) => handleConfirm(e)} >
          <DialogContent>
            <DialogContentText>
              Please enter OTP sent to your mail to confirm registration.
            </DialogContentText>
            <TextField
              margin="dense"
              id="otp"
              label="OTP"
              type="number"
              required
              fullWidth
              placeholder="453588"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit" >{loadingOtp ? "loading..." : "Confirm"}</Button>
          </DialogActions>
        </Box>
      </Dialog>
    </section>
  );
}

export default Register;
