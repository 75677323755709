import axios from "axios";

export const sendContactInfo = async (name, email, phone, message) => {
    let data = JSON.stringify({ "name_string": name, "email_add": email, "phone_num": phone, "desc": message });

    let config = {
        method: 'post',
        url: `${process.env.REACT_APP_BACKEND_API_URL}/send_contactInfo`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    };

    const res = await axios(config).then((res) => {
        console.log("data sent: ", res.data)
        return true;
    }).catch((err) => {
        console.log("error in sending contact info: ", err)
        return null;
    })

    return res;
}