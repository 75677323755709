import axios from "axios";
const URL = process.env.REACT_APP_BACKEND_API_URL;

export const createCoupon = (email, dataString, type, image, name, category, shortDesc, header, consumer, retailer, footer, expiryDate, offer, productImages) => {
    let data = JSON.stringify({ "manufacturer_email": email, "barcode_name": dataString, "barcode_type": type, "barcode_image_base64": image, "product_name": name, "product_category": category, "product_short_description": shortDesc, "header_text": header, "customer_pointers": consumer, "retailer_text": retailer, "footer_text": footer, "expiry_date": expiryDate, "offer_detail": offer, "isUsed": "False", "product_images_base64": productImages });

    let config = {
        method: "POST",
        url: `${URL}/manufacturer/create-coupon/`,
        headers: {
            "Content-Type": "application/json"
        },
        data: data,
    }

    const res = axios(config).then((res) => {
        return res.data;
    }).catch((err) => {
        console.log("error creating coupon: ", err)
        return null;
    })

    return res;
}

export const getCoupons = (email) => {
    let data = JSON.stringify({ "manufacturer_email": email });

    const config = {
        method: "POST",
        url: `${URL}/manufacturer/manufacturer-coupons/`,
        headers: {
            "Content-Type": "application/json"
        },
        data: data,
    }

    const res = axios(config).then((res) => {
        return res.data;
    }).catch((err) => {
        console.log("error in getCoupons: ", err);
        return null;
    })

    return res;
}

export const deleteCoupon = (manufacturer_id, coupon_id) => {

    const config = {
        method: "DELETE",
        url: `${URL}/admin/delete-coupon/${manufacturer_id}/${coupon_id}/`
    }

    const res = axios(config).then((res) => {
        return true;
    }).catch((err) => {
        console.log("error deleting coupon", err);
        return null;
    })

    return res;
}

export const getUsageCoupons = (userId) => {
    let data = JSON.stringify({
        "user_id": userId
    });

    let config = {
        method: 'post',
        url: `${URL}/manufacturer/used-coupons/`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    };

    const res = axios(config)
        .then((response) => {
            return response.data
        })
        .catch((error) => {
            console.log("error in get usage coupons: ", error);
            return null;
        });

    return res;
}