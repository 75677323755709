// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.textEditor {
  margin-top: 20px;
  height: 150px;
  margin-bottom: 55px;
  color: black !important;
  font-size: 10px;
}

.errorText {
  color: #d32f2f;
  margin: 0px !important;
  font-size: 13px;
}
.productImage {
  max-width: 300px;
  max-height: 300px;
  border-radius: 20px;
  margin-bottom: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}`, "",{"version":3,"sources":["webpack://./src/components/productCoupon/ProductCoupon.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,sBAAsB;EACtB,eAAe;AACjB;AACA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,mBAAmB;EACnB,uCAAuC;AACzC","sourcesContent":[".textEditor {\n  margin-top: 20px;\n  height: 150px;\n  margin-bottom: 55px;\n  color: black !important;\n  font-size: 10px;\n}\n\n.errorText {\n  color: #d32f2f;\n  margin: 0px !important;\n  font-size: 13px;\n}\n.productImage {\n  max-width: 300px;\n  max-height: 300px;\n  border-radius: 20px;\n  margin-bottom: 10px;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
