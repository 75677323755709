import React, { useEffect, useState } from "react";
import './Coupons.css';
import MainWrapper from "../../components/layout/layout";
import { Navigate, useLoaderData } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Barcodes } from "../../components/barcodes/Barcodes";
import { QRcodes } from "../../components/qrcodes/QRcodes";
import Spinner from 'react-bootstrap/Spinner';
import { deleteCoupon, getCoupons } from "../../services/coupon";
import { Coupon } from "../../components/coupon/Coupon";
import Skeleton from '@mui/material/Skeleton';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DeleteModal } from "../../components/modal/DeleteModal";

function Coupons() {
  const loader = useLoaderData()
  const data = JSON.parse(loader)
  const [coupons, setCoupons] = useState(null)
  const [loading, setLoading] = useState(false)
  const [modalShow, setModalShow] = useState(false);
  const [deleteManufacturerId, setDeleteManufacturerId] = useState(null);
  const [deleteCouponId, setDeleteCouponId] = useState(null);

  useEffect(() => {
    document.title = "User Coupons"

    return async () => {
      setLoading(true)

      if (data === null) {
        return;
      }
      const res = await getCoupons(data?.email)

      if (res === null) {
        setCoupons([])
        setLoading(false)
        alert("something went wrong, please try later")
        return;
      }

      setCoupons(res)
      setLoading(false)
      return;;
    }
  }, [])

  const handleCouponDelete = async (manufacturer_id, coupon_id) => {
    setDeleteManufacturerId(manufacturer_id)
    setDeleteCouponId(coupon_id)
    setModalShow(true)
    return;
  }

  const confirmCouponDelete = async () => {
    const res = await deleteCoupon(deleteManufacturerId, deleteCouponId)

    if (res === null) {
      alert("something went wrong, please try later")
      return;
    }

    setCoupons((coupons) => coupons.filter(coupon => coupon.coupon_id !== deleteCouponId))
    return;
  }

  if (loader === null) {
    return (
      <div className="loaderParent">
        <Spinner animation="border" variant="primary" />
        <Navigate to="/login/coupons" replace={true} />
      </div>
    )
  }

  return (
    <MainWrapper>
      <div className="content-container">
        <Grid container >
          <Grid item xs={12}>
            <div className="tabs">
              <Tabs
                defaultActiveKey="barcode"
                id="justify-tab-example"
              >
                <Tab className="tab" eventKey="barcode" title="Barcode">
                  <Barcodes />
                </Tab>
                <Tab className="tab" eventKey="qrcode" title="QR code">
                  <QRcodes />
                </Tab>
                <Tab className="tab" eventKey="coupon" title="Coupon">
                  <div className="couponsContainer">
                    {loading ? <Skeleton variant="rounded" width={390} height={120} /> :
                      coupons?.length > 0 ? coupons?.map((coupon, id) => {
                        return (
                          <div className="coupon" key={id}>
                            <Coupon data={coupon} handleCouponDelete={handleCouponDelete} />
                          </div>
                        )
                      }) : <h4 className="mb-0">Coupon is not available</h4>
                    }
                  </div>
                </Tab>
              </Tabs>
            </div>
          </Grid>
        </Grid>

        {/* delete modal */}
        <DeleteModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          header="Delete coupon"
          body="Delete coupon? This can't be undo."
          confirmDelete={confirmCouponDelete} />
      </div>
    </MainWrapper>
  );
}

export default Coupons;
