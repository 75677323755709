import React, { useEffect, useState } from "react";
import { Link, useNavigate,useLoaderData} from "react-router-dom";
import HamburgerICon from '../../assets/images/menu.svg';
import './layout.css';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Logout from '@mui/icons-material/Logout';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import { getUser } from "../../services/user";
const Header = ({ onButtonClick }) => {
  const [name, setName] = useState("")
  const navigate = useNavigate()
  const loader = useLoaderData();
  const [imageUrl, setImageUrl] = useState(null) 
  const [loading, setLoading] = useState(true);
  const user = JSON.parse(loader);
  const userLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("tokens");
    window.location.reload()
  }
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setName(JSON.parse(localStorage.getItem("user"))?.preferred_username || "")
  }, [])
  useEffect(() => {
    const getImage = async () => {
      try {
        const res = await getUser(user?.email);
        setImageUrl(res?.user_profile_pic_url);
      } catch (error) {
        console.error("Failed to fetch user image:", error);
      } finally {
        setLoading(false);
      }
    };
  
    getImage(); 
  }, [user?.email])

  if (loading) {
    return <CircularProgress />; 
  }
  return (
    <nav data-v-18ca20c2="" className="navbar navbar-expand top-header">
      <Link className="sidebar-toggle js-sidebar-toggle" onClick={onButtonClick}>
        <img src={HamburgerICon} alt="iClipCoupons" />
      </Link>
      <h2 className="page-title">{document.title}</h2>
      <div className="navbar-collapse collapse">
        <ul className="navbar-nav navbar-align">
          <li className="nav-item dropdown username-dropdown">
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
              <Typography >{name}</Typography>
              <Tooltip title="Account settings">
                <IconButton
                  onClick={handleClick}
                  size="small"
                  sx={{ ml: 2 }}
                  aria-controls={open ? 'account-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                >
                   <Avatar
                   className="contain"
                    src={imageUrl}
                    alt="User Avatar"
                    sx={{ width: 36, height: 36, ojectFit: 'contain'}}
                    variant="circular"
                    />
                </IconButton>
              </Tooltip>
            </Box>
          </li>
        </ul>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              padding: '10px',
              borderRadius: '10px',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.1))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem
            onClick={() => navigate("/profile/login")}
            PaperProps={{
              sx: {
                borderRadius: '10px',
              },
            }}
          >
            <Avatar
              src={imageUrl}
              alt="User Avatar"
              sx={{ width: 48, height: 48,ojectFit: 'contain'}}
              variant="circular"
            /> Profile
          </MenuItem>
          <MenuItem onClick={userLogout} >
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </div>
    </nav>
  );
}

export default Header;