import React, { useEffect, useState } from "react";
import MainWrapper from "../../components/layout/layout";
import { Navigate, useLoaderData, useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Spinner from "react-bootstrap/Spinner";
import { deleteCoupon, getUsageCoupons } from "../../services/coupon";
import { Coupon } from "../../components/coupon/Coupon";
import Skeleton from "@mui/material/Skeleton";
import { DeleteModal } from "../../components/modal/DeleteModal";

export const CouponUsage = () => {
  const loader = JSON.parse(useLoaderData());
  const [coupons, setCoupons] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [deleteManufacturerId, setDeleteManufacturerId] = useState(null);
  const [deleteCouponId, setDeleteCouponId] = useState(null);
  const { userId } = useParams();

  useEffect(() => {
    document.title = "Coupon usage";

    return async () => {
      setLoading(true);
      const res = await getUsageCoupons(userId);

      if (res === null) {
        setCoupons([]);
        setLoading(false);
        alert("something went wrong, please try later");
        return;
      }

      setCoupons(res);
      setLoading(false);
      return;
    };
  }, []);

  const handleCouponDelete = async (manufacturer_id, coupon_id) => {
    setDeleteManufacturerId(manufacturer_id);
    setDeleteCouponId(coupon_id);
    setModalShow(true);
    return;
  };

  const confirmCouponDelete = async () => {
    const res = await deleteCoupon(deleteManufacturerId, deleteCouponId);

    if (res === null) {
      alert("something went wrong, please try later");
      return;
    }

    setCoupons((coupons) =>
      coupons.filter((coupon) => coupon.coupon_id !== deleteCouponId)
    );
    return;
  };

  if (loader === null) {
    return (
      <div className="loaderParent">
        <Spinner animation="border" variant="primary" />
        <Navigate to="/login/manufacturers" replace={true} />
      </div>
    );
  }

  return (
    <MainWrapper>
      <div className="content-container">
        <Grid container>
          <Grid sx={{ mx: 5 }} item xs={12}>
            <div className="manufacturerCouponsContainer">
              {loading ? (
                <Skeleton variant="rounded" width={390} height={120} />
              ) : coupons?.length > 0 ? (
                coupons?.map((coupon, id) => {
                  return (
                    <div key={id}>
                      <Coupon
                        data={coupon}
                        handleCouponDelete={handleCouponDelete}
                      />
                    </div>
                  );
                })
              ) : (
                <h4>Coupons is not available</h4>
              )}
            </div>
          </Grid>
        </Grid>

        {/* delete Modal */}
        <DeleteModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          header="Delete coupon"
          body="Delete coupon? This can't be undo."
          confirmDelete={confirmCouponDelete}
        />
      </div>
    </MainWrapper>
  );
};
