import React, { useState, useEffect } from "react";
import Logo from "../../assets/images/landing_page/logo.png";
import './layout.css';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import CategoryIcon from '@mui/icons-material/Category';
import GroupsIcon from '@mui/icons-material/Groups';
import { useNavigate, Link, useLoaderData } from 'react-router-dom';

function Sidebar() {
    const navigate = useNavigate()
    const [page, setPage] = useState("")
    const [isAdmin, setIsAdmin] = useState(false)
    const data = JSON.parse(useLoaderData())

    useEffect(() => {
        if (data?.email === process.env.REACT_APP_ADMIN_EMAIL) {
            setIsAdmin(true)
            return;
        }
    }, [])

    const handleMenuClick = (data) => {
        navigate(data)
    };

    useEffect(() => {
        return () => {
            setPage(window.location.pathname)
        }
    }, [])

    return (
        <nav data-v-18ca20c2="" id="sidebar" className="sidebar js-sidebar">
            <div className="sidebar-content js-simplebar">
                <Link to="/" className="sidebar-brand active text-center">
                    <img src={Logo} alt="iClipCoupons" className="site-logo" />
                </Link>
                <span className="close-menu d-none">
                    <div className="d-inline-block">
                        <img src={Logo} alt="iClipCoupons" />
                    </div>
                </span>
                <ul className="sidebar-nav">
                    <li className="nav-header"><span className="nav-label">NAVIGATION</span>
                    </li>
                    {/* 1st nav */}
                    <li className="sidebar-item" onClick={() => handleMenuClick("/barcode/login")} >
                        <a aria-current="page" className={`sidebar-link  ${page === "/barcode/login" ? "exact-active active" : ""}`}>
                            {/*<span className="shape1"></span><span className="shape2"></span>*/}
                            <div className="d-inline-block">
                                <ConfirmationNumberIcon className="menuIcon" />
                            </div>
                            <span className="align-middle">Create Barcode</span>
                        </a>
                    </li>

                    {/* 2nd nav */}
                    <li className="sidebar-item" onClick={() => handleMenuClick("/qrcode/login")} >
                        <a aria-current="page" className={`sidebar-link  ${page === "/qrcode/login" ? "exact-active active" : ""}`}>
                            {/*<span className="shape1"></span><span className="shape2"></span>*/}
                            <div className="d-inline-block">
                                <QrCode2Icon className="menuIcon" />
                            </div>
                            <span className="align-middle">Create QR Code</span>
                        </a>
                    </li>

                    {/* 3rd nav */}
                    <li className={`sidebar-item ${isAdmin ? "" : "hide"}`} onClick={() => handleMenuClick("/companyprefixes/login")} >
                        <a aria-current="page" className={`sidebar-link  ${page === "/companyprefixes/login" ? "exact-active active" : ""}`} >
                            {/*<span className="shape1"></span><span className="shape2"></span>*/}
                            <div className="d-inline-block">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-home"
                                >
                                    <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                                    <polyline points="9 22 9 12 15 12 15 22"></polyline>
                                </svg>
                            </div>
                            <span className="align-middle">Add Company Prefixes</span>
                        </a>
                    </li>

                    {/* 4th nav */}
                    <li className={`sidebar-item ${isAdmin ? "" : "hide"}`} onClick={() => handleMenuClick("/productcategory/login")} >
                        <a aria-current="page" className={`sidebar-link  ${page === "/productcategory/login" ? "exact-active active" : ""}`} >
                            {/*<span className="shape1"></span><span className="shape2"></span>*/}
                            <div className="d-inline-block">
                                <CategoryIcon className="menuIcon" />
                            </div>
                            <span className="align-middle">Add Product Category</span>
                        </a>
                    </li>

                    {/* 5th nav */}
                    <li className="sidebar-item" onClick={() => handleMenuClick("/coupons/login")} >
                        <a aria-current="page" className={`sidebar-link  ${page === "/coupons/login" ? "exact-active active" : ""}`} >
                            {/*<span className="shape1"></span><span className="shape2"></span>*/}
                            <div className="d-inline-block">
                                <AppRegistrationIcon className="menuIcon" />
                            </div>
                            <span className="align-middle">Generated Coupons</span>
                        </a>
                    </li>

                    {/* 6th nav */}
                    <li className={`sidebar-item ${isAdmin ? "" : "hide"}`} onClick={() => handleMenuClick("/manufacturers/login")} >
                        <a aria-current="page" className={`sidebar-link  ${page === "/manufacturers/login" ? "exact-active active" : ""}`} >
                            {/*<span className="shape1"></span><span className="shape2"></span>*/}
                            <div className="d-inline-block">
                                <GroupsIcon className="menuIcon" />
                            </div>
                            <span className="align-middle">Manufacturers</span>
                        </a>
                    </li>
                </ul>
            </div>
        </nav>

    );
}

export default Sidebar;
