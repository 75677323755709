import React from "react";

export const Footer = () => {
  return (
    <div className="section footer-section">
      <div className="container">
        <p className="copyright text-center">Copyright © 2010-2024 iClipCoupons.</p>
      </div>
    </div>
  );
};
