import React, { useState, useEffect } from "react";
import './auth.css'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Logo from "../../assets/images/landing_page/logo.png";
import { Auth } from 'aws-amplify';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Navigate, Link, useSearchParams, useNavigate } from "react-router-dom";
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

function Login() {
  const [params] = useSearchParams()
  const navigate = useNavigate()

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(false);
  const [isloggedIn, setIsloggedIn] = useState(false);
  const [open, setOpen] = useState(false);
  const [errorText, setErrorText] = useState("")

  const returnPath = window.location.pathname.split("/")[2]

  const handleSubmit = async (e) => {
    e.preventDefault()

    setLoading(true)
    setOpen(false)
    try {
      const user = await Auth.signIn(email, password);
      localStorage.setItem("user", JSON.stringify(user.attributes))
      setIsloggedIn(true)
      setLoading(false)
      localStorage.setItem("tokens", JSON.stringify(user.signInUserSession
      ))
      navigate(`/${returnPath}/login`)
    } catch (error) {
      setLoading(false)
      console.log('error signing in', error);

      if (error.name === "NotAuthorizedException") {
        setErrorText("Incorrect username or password.")
        setOpen(true)
        return;
      }

      if (error.name === "UserNotFoundException") {
        setErrorText("User does not exist.")
        setOpen(true)
        return;
      }

      setErrorText("Something went wrong, please try again later.")
      setOpen(true)
      return;
    }
  }

  useEffect(() => {
    let user = localStorage.getItem("user")
    if (user) {
      setIsloggedIn(true)
    }
  }, [])

  return (
    <section className="full-page-layout">
      {isloggedIn && (
        <Navigate to="/barcode/login" replace={true} />
      )}
      <div className="full-height">
        <div className="login-area w-100">
          <div className="form-area">
            <Collapse in={open}>
              <Alert severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                sx={{ mb: 2 }}
              >
                {errorText}
              </Alert>
            </Collapse>
            <div className="text-center mb-4">
              <img className="authLogo" onClick={() => navigate("/")} src={Logo} alt="iClipCoupons" />
            </div>
            <h3 className="mb-3 auth-title">Login into your account</h3>
            <Box component="form" onSubmit={(e) => handleSubmit(e)}>
              <TextField
                className="input-field"
                margin="normal"
                required
                fullWidth
                id="email"
                type="email"
                label="Email"
                name="email"
                autoFocus={true}
                autoComplete="email" value={email}
                onChange={(e) => setEmail(e.target.value)}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '30px',  // Custom border-radius
                  }
                }}
              />
              <TextField
                className="input-field"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '30px',  // Custom border-radius
                  }
                }}
              />
              <Button
                className="auth-btn"
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                {loading ? "loading..." : "Submit"}
              </Button>
              <div className="text-center">
                <div>
                  Don't have an account?
                  <Link to="/register" className="auth-link ms-1">
                    {"Sign up"}
                  </Link>
                </div>
                <div className="mt-2">
                  Forgot password?
                  <Link to="/forgot-password" className="auth-link ms-1">
                    {"Reset it"}
                  </Link>
                </div>
              </div>
            </Box>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Login;
