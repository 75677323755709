// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.updateImageContainer {
  width: 100%;
  height: 120px;
  /* background-color: aqua; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.updateProfileImage {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  object-fit: fill;
}

.updateBtnContainer {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  flex-wrap: wrap;
}
`, "",{"version":3,"sources":["webpack://./src/components/profile/updateUser/UpdateUser.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,4BAA4B;EAC5B,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,SAAS;EACT,eAAe;AACjB","sourcesContent":[".updateImageContainer {\n  width: 100%;\n  height: 120px;\n  /* background-color: aqua; */\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n}\n\n.updateProfileImage {\n  width: 90px;\n  height: 90px;\n  border-radius: 50%;\n  object-fit: fill;\n}\n\n.updateBtnContainer {\n  margin-top: 20px;\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  gap: 20px;\n  flex-wrap: wrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
