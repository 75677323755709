import axios from "axios"
const URL = process.env.REACT_APP_BACKEND_API_URL

export const addProductCategory = (category) => {
    const data = JSON.stringify({ "product_category_name": category })

    const config = {
        method: "POST",
        url: `${URL}/admin/add-ProductCategory`,
        headers: { 'Content-Type': 'application/json' },
        data: data,
    }

    const res = axios(config).then((res) => {
        return res.data
    }).catch((err) => {
        console.log("error in addProductCategory: ", err)
        return null;
    })

    return res;
}

export const getProductCategory = () => {

    const config = {
        method: "GET",
        url: `${URL}/admin/view-ProductCategories`,
    }

    const res = axios(config).then((res) => {
        return res.data
    }).catch((err) => {
        console.log("error in getProductCategory: ", err)
        return null;
    })

    return res;
}
export const deleteProductCategory = (id) => {
    let config = {
        method: 'delete',
        url: `${URL}/admin/delete-ProductCategory/${id}/`,
    };

    const data = axios.request(config)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log("error in delete productCategory: ", error);
            return null;
        });

    return data;
}

export const updateProductCategory = (id, category) => {
    let data = JSON.stringify({
        "product_category_name": category
    });

    let config = {
        method: 'patch',
        url: `${URL}/admin/update-ProductCategory/${id}/`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    };

    const res = axios.request(config)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log("error in update product category: ", error);
            return null;
        });

    return res;
}