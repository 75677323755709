import axios from "axios";
const URL = process.env.REACT_APP_BACKEND_API_URL;

export const getUser = (email) => {
    let data = JSON.stringify({ "user_email": email });

    let config = {
        method: 'post',
        url: `${URL}/accounts/get_user/`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    };

    const res = axios(config)
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log("error in getUser", error);
            return null;
        });

    return res;
}

const isValidURL = (string) => {
    var res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    return (res !== null)
};

export const updateUser = (img, username, email) => {

    let data;

    if (isValidURL(img)) {
        data = JSON.stringify({
            "user_name": username,
        });
    } else {
        data = JSON.stringify({
            "user_name": username,
            "user_profile_pic_base64": img
        });
    }

    // let data = JSON.stringify({
    //     "user_name": username,
    //     "user_profile_pic_base64": img
    // });

    let config = {
        method: 'put',
        url: `${URL}/accounts/update_user/${email}/`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    };

    let res = axios(config)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log("error in update user: ", error);
            return null;
        });

    return res;
}

export const addUser = (username, email) => {
    let data = JSON.stringify({
        "user_name": username,
        "user_email": email,
        "role": "Manufacturer",
        "user_profile_pic_base64": null
    });

    let config = {
        method: 'post',
        url: `${URL}/accounts/save_user/`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    };

    const res = axios(config)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log("error in add user: ", error);
            return null;
        });

    return res;
}