import React, { useState, useEffect } from "react";
import './barcodeExpandedStacked.css';
import MainWrapper from "../../components/layout/layout";
import { json, Navigate, useLoaderData, useNavigate } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Stack from '@mui/material/Stack';
import MailIcon from '@mui/icons-material/Mail';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import Skeleton from '@mui/material/Skeleton';
import Autocomplete from '@mui/material/Autocomplete';
import axios from "axios";
import { Container } from "@mui/system";
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import bwipjs from "bwip-js";
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { MyDocument } from "./doc";
import { pdf } from '@react-pdf/renderer';
import { imageUpload } from "../../services/image";
import { getImageDimensions, haveNegativeOrDecimal, numericFilter } from "../../utils/utils";
import Spinner from 'react-bootstrap/Spinner';
import AddIcon from '@mui/icons-material/Add';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import { ProductDetail } from "../../components/ProductDetail/ProductDetail";
import { ProductCoupon } from "../../components/productCoupon/ProductCoupon";
import { getCompanyPrefix } from "../../services/companyPrefix";

function BarcodeExpandedStacked() {
  const navigate = useNavigate()
  const loader = useLoaderData()
  const backendApiUrl = process.env.REACT_APP_BACKEND_API_URL

  const [data, setData] = useState({})
  const [isValueChanging, setIsValueChanging] = useState(true)
  const [companyPrefixes, setCompanyPrefixes] = useState([])
  const [email, setEmail] = useState("")
  const [open, setOpen] = useState(false);
  const [alertType, setAlertType] = useState("success");
  const [alertText, setAlertText] = useState("");
  const [src, setImageSrc] = useState(false);
  const [fieldType, setFieldType] = useState("");
  const [fileType, setFileType] = useState("png");
  const [imageDimension, setImageDimension] = useState({});
  const [download, setDownload] = useState(null);
  const [dataString, setDataString] = useState("");
  const [reportDownloaded, setReportDownloaded] = useState(false);
  const [inpFieldError, setInpFieldError] = useState(false);
  const [additionalFieldRequired, setAdditionalFieldRequired] = useState(false);
  const [valueCodeFlagAppliesRequired, setValueCodeFlagAppliesRequired] = useState(false);
  const [companyPrefixReq, setCompanyPrefixReq] = useState(false);
  const [offerCodeReq, setOfferCodeReq] = useState(false);
  const [saveValueReq, setSaveValueReq] = useState(false);
  const [primaryPurchaseRequirementReq, setPrimaryPurchaseRequirementReq] = useState(false);
  const [primaryPurchaseRequirementCodeReq, setPrimaryPurchaseRequirementCodeReq] = useState(false);
  const [primaryPurchaseFamilyCodeReq, setPrimaryPurchaseFamilyCodeReq] = useState(false);
  const [imageUploaded, setImageUploaded] = useState(false)
  const [showSecondPurchase, setShowSecondPurchase] = useState(false)
  const [showSerialNumber, setShowSerialNumber] = useState(false)
  const [showRetailer, setShowRetailer] = useState(false)
  const [freeCoupon, setFreeCoupon] = useState(false)
  const [limitedPerOne, setLimitedPerOne] = useState(false)
  const [isAdmin, setIsAdmin] = useState(false)
  const [expiryDate, setExpiryDate] = useState(null)
  const [startDate, setStartDate] = useState(null)

  // check if user is admin or not
  useEffect(() => {
    document.title = "Create Your Barcode"

    const data = JSON.parse(loader)
    if (data?.email === process.env.REACT_APP_ADMIN_EMAIL) {
      setIsAdmin(true)
      return;
    }
  }, [])

  // implement free coupon and limited per one functionality in form field
  useEffect(() => {
    if (freeCoupon && !limitedPerOne) {
      setData({ ...data, "saveValueCode": 1, "multiplyFlag": "", "saveValueAppliesItem": "", "storeCoupon": "" })
    } else if (limitedPerOne && !freeCoupon) {
      setData({ ...data, "multiplyFlag": 1, "saveValueCode": "", "saveValueAppliesItem": "", "storeCoupon": "" })
    } else if (freeCoupon === true && limitedPerOne === true) {
      setData({ ...data, "saveValueAppliesItem": "0", "storeCoupon": "0", "saveValueCode": 1, "multiplyFlag": 1 })
    } else {
      setData({ ...data, "saveValueAppliesItem": "", "storeCoupon": "", "saveValueCode": "", "multiplyFlag": "" })
    }
  }, [freeCoupon, limitedPerOne])

  const generateBarcode = async (downloadRatio = 1) => {
    try {
      const string = `(8110)${data.companyPrefix.length - 6}${data.companyPrefix}${data.offerCode}${data.saveValue.length}${data.saveValue}${data.primaryPurchaseRequirement.length}${data.primaryPurchaseRequirement}${data.primaryPurchaseRequirementCode}${data.primaryPurchaseFamilyCode}${data.additionalPurchaseRuleCode ? `1${data.additionalPurchaseRuleCode}${data.secondPurchaseRequirement.length}${data.secondPurchaseRequirement}${data.secondPurchaseRequirementCode}${data.secondPurchaseFamilyCode}${data.secondCompanyPrefix.length - 6}${data.secondCompanyPrefix}` : ''}${data.expiryDate ? `3${data.expiryDate}` : ''}${data.startDate ? `4${data.startDate}` : ''}${data.serialNumber ? `5${data.serialNumber.length - 6}${data.serialNumber}` : ''}${data.retailerCompanyPrefix ? `6${data.retailerCompanyPrefix.length - 6}${data.retailerCompanyPrefix}` : ''}${data.saveValueCode ? `9${data.saveValueCode}${data.saveValueAppliesItem}${data.storeCoupon}${data.multiplyFlag}` : ''}`

      let segment = 4;

      if (string.length < 35) {
        segment = 6;
      } else if (string.length >= 35 && string.length < 50) {
        segment = 8;
      } else {
        segment = 12;
      }

      let scale = 1
      if (downloadRatio === 2 || downloadRatio === 3) {
        // segment = (downloadRatio === 2) ? 10 : 12;
        scale = (downloadRatio === 2) ? 2 : 3;
      }

      let canvas = document.createElement("canvas");

      await bwipjs.toCanvas(canvas, {
        bcid: "databarexpandedstacked", // Barcode type
        text: string,
        alttext: `${data.companyPrefix}-${data.offerCode}`,
        segments: segment,
        scale: scale,
        textsize: 16,
        // height: 0.83,
        // width: 2.04,
        includetext: true,
        padding: 0,
        textyalign: "above",
        backgroundcolor: "#FFFFFF",
        textxalign: 'left',
      })

      const base64 = canvas.toDataURL("image/png")
      const finalString = string.split("(").join("").split(")").join("")
      setDataString(finalString)
      setReportDownloaded(false)
      setImageUploaded(false)
      setOpen(false);
      if (downloadRatio === 1) {
        setImageSrc(base64);
        const dimension = await getImageDimensions(base64)
        setImageDimension(dimension)
        return;
      }
      else {
        return base64
      }

    } catch (error) {
      setImageSrc(false);
      alert(error.message.split(":").pop())
      console.log("barcode error: ", error)
      return;
    }
  }

  // get GS1 company prefix
  const getPrefix = async () => {
    setCompanyPrefixes([])
    const res = await getCompanyPrefix();

    if (res === null) {
      return;
    }

    res.map((d) => [
      setCompanyPrefixes(data => [...data, { "label": d.company_name, "value": d.gs1_company_prefix }])
    ])
    return;
  }

  useEffect(() => {
    return () => getPrefix()
  }, [])

  // additional products fields required set
  useEffect(() => {
    if (data.additionalPurchaseRuleCode && data.additionalPurchaseRuleCode.length !== 0) {
      setAdditionalFieldRequired(true)
    } else {
      setAdditionalFieldRequired(false)

    }

    return () => {

    }
  }, [data.additionalPurchaseRuleCode])

  // last four fields fields required set
  useEffect(() => {
    if (data.saveValueCode || data.saveValueAppliesItem || data.multiplyFlag || data.storeCoupon) {
      setValueCodeFlagAppliesRequired(true)
    } else {
      setValueCodeFlagAppliesRequired(false)

    }

    return () => {

    }
  }, [data.saveValueCode, data.saveValueAppliesItem, data.multiplyFlag, data.storeCoupon])

  const handleChange = (e) => {
    setIsValueChanging(true)
    setData({ ...data, [e.target.name]: e.target.value })

    if (e.target.name === "offerCode" && (e.target.value.length !== 6 || haveNegativeOrDecimal(e.target.value))) {
      setFieldType("offerCode")
      setInpFieldError(true)
      return;
    }
    setFieldType("")

    if (e.target.name === "saveValue" && (e.target.value.length > 5 || haveNegativeOrDecimal(e.target.value))) {
      setFieldType("saveValue")
      setInpFieldError(true)
      return;
    }
    setFieldType("")

    if (e.target.name === "primaryPurchaseRequirement" && (e.target.value.length > 5 || haveNegativeOrDecimal(e.target.value))) {
      setFieldType("primaryPurchaseRequirement")
      setInpFieldError(true)
      return;
    }
    setFieldType("")

    if (e.target.name === "primaryPurchaseRequirementCode" && e.target.value.length !== 1) {
      setFieldType("primaryPurchaseRequirementCode")
      setInpFieldError(true)
      return;
    }
    setFieldType("")

    if (e.target.name === "primaryPurchaseFamilyCode" && (e.target.value.length !== 3 || haveNegativeOrDecimal(e.target.value))) {
      setFieldType("primaryPurchaseFamilyCode")
      setInpFieldError(true)
      return;
    }
    setFieldType("")

    if (e.target.name === "additionalPurchaseRuleCode" && e.target.value.length !== 1) {
      setFieldType("additionalPurchaseRuleCode")
      setInpFieldError(true)
      return;
    }
    setFieldType("")

    if (e.target.name === "secondPurchaseRequirement" && (e.target.value.length > 5 || haveNegativeOrDecimal(e.target.value))) {
      setFieldType("secondPurchaseRequirement")
      setInpFieldError(true)
      return;
    }
    setFieldType("")

    if (e.target.name === "secondPurchaseRequirementCode" && e.target.value.length !== 0 && e.target.value.length !== 1) {
      setFieldType("secondPurchaseRequirementCode")
      setInpFieldError(true)
      return;
    }
    setFieldType("")

    if (e.target.name === "secondPurchaseFamilyCode" && (e.target.value.length !== 3 || haveNegativeOrDecimal(e.target.value))) {
      setFieldType("secondPurchaseFamilyCode")
      setInpFieldError(true)
      return;
    }
    setFieldType("")

    if (e.target.name === "serialNumber" && (e.target.value.length < 6 || e.target.value.length > 15 || haveNegativeOrDecimal(e.target.value))) {
      setFieldType("serialNumber")
      setInpFieldError(true)
      return;
    }
    setFieldType("")

    if (e.target.name === "saveValueCode" && e.target.value.length !== 0 && e.target.value !== "0" && e.target.value !== "1") {
      setFieldType("saveValueCode")
      setInpFieldError(true)
      return;
    }
    setFieldType("")

    if (e.target.name === "saveValueAppliesItem" && e.target.value.length !== 0 && e.target.value !== "0" && e.target.value !== "1") {
      setFieldType("saveValueAppliesItem")
      setInpFieldError(true)
      return;
    }
    setFieldType("")

    if (e.target.name === "storeCoupon" && e.target.value.length !== 0 && e.target.value !== "0" && e.target.value !== "1") {
      setFieldType("storeCoupon")
      setInpFieldError(true)
      return;
    }
    setFieldType("")

    if (e.target.name === "multiplyFlag" && e.target.value.length !== 0 && e.target.value !== "0" && e.target.value !== "1") {
      setFieldType("multiplyFlag")
      setInpFieldError(true)
      return;
    }
    setFieldType("")


    setInpFieldError(false)
  }

  const handleDropdownChange = (e, rowValue, reason) => {
    setIsValueChanging(true)
    if (reason === "selectOption") {
      setData({ ...data, "companyPrefix": rowValue.value })
    } else {
      return;
    }
  }

  const handleSecondDropdownChange = (e, rowValue, reason) => {
    setIsValueChanging(true)
    if (reason === "selectOption") {
      setData({ ...data, "secondCompanyPrefix": rowValue.value })
    } else if (reason === "clear") {
      delete data.secondCompanyPrefix
    } else {
      return;
    }
  }

  const handleRetailerDropdownChange = (e, rowValue, reason) => {
    setIsValueChanging(true)
    if (reason === "selectOption") {
      setData({ ...data, "retailerCompanyPrefix": rowValue.value })
    } else if (reason === "clear") {
      delete data.retailerCompanyPrefix
    } else {
      return;
    }
  }

  // download report PDF 
  const downloadPDF = async () => {

    if (reportDownloaded) {
      return;
    }

    const blob = await pdf(<MyDocument data={data} string={dataString} />).toBlob();
    let a = document.createElement("a");
    document.body.appendChild(a);

    let url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = "coupon_report.pdf";
    a.click();
    window.URL.revokeObjectURL(url);
    setReportDownloaded(true)
    return;
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!data.companyPrefix) {
      setCompanyPrefixReq(true)
      setInpFieldError(true)
    } else {
      setCompanyPrefixReq(false)
    }

    if (!data.offerCode) {
      setOfferCodeReq(true)
      setInpFieldError(true)
    } else {
      setOfferCodeReq(false)
    }

    if (!data.saveValue) {
      setSaveValueReq(true)
      setInpFieldError(true)
    } else {
      setSaveValueReq(false)
    }

    if (!data.primaryPurchaseRequirement) {
      setPrimaryPurchaseRequirementReq(true)
      setInpFieldError(true)
    } else {
      setPrimaryPurchaseRequirementReq(false)
    }

    if (!data.primaryPurchaseRequirementCode) {
      setPrimaryPurchaseRequirementCodeReq(true)
      setInpFieldError(true)
    } else {
      setPrimaryPurchaseRequirementCodeReq(false)
    }

    if (!data.primaryPurchaseFamilyCode) {
      setPrimaryPurchaseFamilyCodeReq(true)
      setInpFieldError(true)
    } else {
      setPrimaryPurchaseFamilyCodeReq(false)
    }

    if (!data.companyPrefix || !data.saveValue || !data.offerCode || !data.primaryPurchaseRequirement || !data.primaryPurchaseRequirementCode || !data.primaryPurchaseFamilyCode || inpFieldError) {
      return;
    }

    console.log("data: ", data)

    await generateBarcode(1)
    setIsValueChanging(false)
  }

  const uploadBarcode = async () => {

    if (imageUploaded) {
      return;
    }

    const data = await imageUpload(src, dataString, "Barcode")

    if (data === "File saved successfully") {
      setImageUploaded(true)

      setTimeout(() => {
        setOpen(false);
      }, 1500);

      setTimeout(() => {
        setAlertType("success")
        setAlertText("Barcode successfully Uploaded")
        setOpen(true);
      }, 3000);

      // clearTimeout(timer);
      return;
    }

    if (data === null) {
      setImageUploaded(false)
      setAlertType("error")
      setAlertText("Some error occurred while uploading Image")
      setOpen(true);
      return;
    }
  }

  // download barcode
  const handleDownload = async (downloadRatio = 1, baseSrc = null) => {
    const downloadLink = document.createElement("a");

    downloadPDF();
    uploadBarcode();

    if (fileType === "png") {

      let tmpSrc = (downloadRatio !== 1) ? baseSrc : src;
      axios.post(`${backendApiUrl}/manufacturer/base-to-png/`, { data_string: tmpSrc, name_string: dataString }).then((res) => {
        downloadLink.href = res.data.download_link;
        downloadLink.click();
        return;
      }).catch((err) => {
        console.log("error while downloading png: ", err)
      })
      return;
    }

    if (fileType === "pdf") {
      let tmpSrc = (downloadRatio !== 1) ? baseSrc : src;
      axios.post(`${backendApiUrl}/manufacturer/base-to-pdf/`, { data_string: tmpSrc, name_string: dataString }).then((res) => {
        downloadLink.href = res.data.download_link;
        downloadLink.click();
        return;
      }).catch((err) => {
        console.log("error while downloading pdf: ", err)
      })
      return;
    }
    if (fileType === "eps") {
      let tmpSrc = (downloadRatio !== 1) ? baseSrc : src;

      axios.post(`${backendApiUrl}/manufacturer/base-to-eps/`, { data_string: tmpSrc, name_string: dataString }).then((res) => {
        downloadLink.href = res.data.download_link;
        downloadLink.click();
        return;
      }).catch((err) => {
        console.log("error in eps: ", err);
        return;
      })
      return;
    }
    return;
  }

  const handleSendMail = async (e) => {
    e.preventDefault();
    var data = JSON.stringify({ "email_add": email, "img_url": src.split(",")[1], "file_type": fileType, "name_string": dataString, "code_type": "Barcode" });
    var config = {
      method: 'post',
      url: `${backendApiUrl}/manufacturer/send/`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };
    await axios(config)
      .then((response) => {
        setAlertType("success")
        setAlertText("Barcode successfully sent.")
        setOpen(true);
        uploadBarcode()
        return;
      })
      .catch((error) => {
        console.log("error while sending mail: ", error);
        setAlertType("error")
        setAlertText("Some error occurred while sending mail")
        setOpen(true);
        return;
      });
    return;
  }

  const onEmailChange = (e) => {
    setEmail(e.target.value)
  }

  const handleDateChange = (e) => {
    setIsValueChanging(true)
    setData({ ...data, [e.target.name]: e.target.value.split("-").join("").slice(2) })

    if (e.target.name === "expiryDate") {
      setExpiryDate(e.target.value)
    }

    if (e.target.name === "startDate") {
      setStartDate(e.target.value)
    }
  }

  const handleFileTypeChange = (e) => {
    setDownload(null)
    setFileType(e.target.value);
  };

  if (loader === null) {
    return (
      <div className="loaderParent">
        <Spinner animation="border" variant="primary" />
        <Navigate to="/login/barcode" replace={true} />
      </div>
    )
  }

  const handleKewDown = (e) => {
    return ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'].includes(e.code) ? true : !isNaN(Number(e.key)) && e.code !== 'Space';
  }

  return (
    <MainWrapper>
      <div id="dpi"></div>
      <div className="content-container">
        <Grid container >
          {/* form field section */}
          <Grid item xs={12} md={6}>
            <Box component="form" onSubmit={handleSubmit} sx={{ m: 3, mt: 1 }} >
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <Autocomplete
                  disablePortal
                  id="companyPrefix"
                  options={companyPrefixes}
                  name="companyPrefix"
                  onChange={handleDropdownChange}
                  fullWidth
                  autoSelect={true}
                  loading={companyPrefixes.length === 0 ? true : false}
                  renderInput={(params) => <TextField fullWidth {...params}
                    label="Primary GS1 Company Prefix"
                    error={companyPrefixReq}
                    helperText={companyPrefixReq && "Company prefix is required"}
                    autoFocus
                  />}
                />
                <Tooltip className={isAdmin ? "" : "hide"} title="Add company prefix" sx={{ ml: 2, my: "auto" }} onClick={() => navigate("/companyprefixes/login")} >
                  <IconButton>
                    <AddIcon />
                  </IconButton>
                </Tooltip>

              </Box>
              <Grid container spacing={2} >
                <Grid item xs={6} md={6} lg={6}>
                  <TextField sx={{ mt: 2 }} id="offerCode" name="offerCode" type="number" label="Offer Code" placeholder="Should be 6 digits" variant="outlined" fullWidth onKeyDown={numericFilter}
                    onChange={handleChange}
                    error={fieldType === "offerCode" || offerCodeReq}
                    helperText={(fieldType === "offerCode" || offerCodeReq) && "Offer Code is required, should be 6 digits and should not contain decimal or minus value"}
                  />
                </Grid>
                <Grid item xs={6} md={6} lg={6} >
                  <TextField sx={{ mt: 2 }} id="saveValue" name="saveValue" type="number" label="Save value" variant="outlined" fullWidth
                    value={data.saveValue || ""} onKeyDown={numericFilter}
                    onChange={handleChange}
                    placeholder="Should be 1 to 5 digits"
                    error={fieldType === "saveValue" || saveValueReq}
                    helperText={(fieldType === "saveValue" || saveValueReq) && "Save value is required, should be 1 to 5 digits and should not contain decimal or minus value"} />

                </Grid>
              </Grid>
              <TextField sx={{ mt: 2 }} id="primaryPurchaseRequirement" name="primaryPurchaseRequirement" type="number" label="Primary Purchase Requirement" variant="outlined" fullWidth
                value={data.primaryPurchaseRequirement || ""}
                onKeyDown={numericFilter}
                onChange={handleChange}
                placeholder="Should be 1 to 5 digits"
                error={fieldType === "primaryPurchaseRequirement" || primaryPurchaseRequirementReq}
                helperText={(fieldType === "primaryPurchaseRequirement" || primaryPurchaseRequirementReq) && "Primary Purchase Requirement is required, should be 1 to 5 digits and should not contain decimal or minus value"}
              />
              <TextField sx={{ mt: 2 }} id="primaryPurchaseRequirementCode" name="primaryPurchaseRequirementCode" type="number" label="Primary Purchase Requirement Code" variant="outlined" fullWidth
                onKeyDown={numericFilter}
                onChange={handleChange}
                placeholder="Should be 1 digit"
                error={fieldType === "primaryPurchaseRequirementCode" || primaryPurchaseRequirementCodeReq}
                helperText={(fieldType === "primaryPurchaseRequirementCode" || primaryPurchaseRequirementCodeReq) && "Primary Purchase Requirement Code is required and should be 1 digit"}
              />
              <TextField sx={{ mt: 2 }} id="primaryPurchaseFamilyCode" name="primaryPurchaseFamilyCode" type="number" label="Primary Purchase Family Code" variant="outlined" fullWidth
                onKeyDown={numericFilter}
                value={data.primaryPurchaseFamilyCode || ""}
                onChange={handleChange}
                placeholder="Should be 3 digits"
                error={fieldType === "primaryPurchaseFamilyCode" || primaryPurchaseFamilyCodeReq}
                helperText={(fieldType === "primaryPurchaseFamilyCode" || primaryPurchaseFamilyCodeReq) && "Primary Purchase Family Code is required, should be 3 digits and should not contain decimal or minus value"}
              />
              <FormGroup sx={{ mt: 2 }}>
                <FormControlLabel style={{ width: "fit-content" }} control={<Checkbox onChange={e => setShowSecondPurchase(!showSecondPurchase)} />} label="Have a Second GS1 Company Prefix?" />
              </FormGroup>
              <Box display={showSecondPurchase ? "" : "none"} >
                <TextField id="additionalPurchaseRuleCode" name="additionalPurchaseRuleCode" type="number" label="Additional Purchase Rule Code" variant="outlined" fullWidth
                  onKeyDown={numericFilter}
                  value={data.additionalPurchaseRuleCode || ""}
                  onChange={handleChange}
                  required={additionalFieldRequired ? true : false}
                  placeholder="Should be 1 digit"
                  error={fieldType === "additionalPurchaseRuleCode"}
                  helperText={fieldType === "additionalPurchaseRuleCode" && "Additional Purchase Rule Code should be 1 digit"}
                />
                <TextField sx={{ mt: 2 }} id="secondPurchaseRequirement" name="secondPurchaseRequirement" type="number" label="Second Purchase Requirement" variant="outlined" fullWidth
                  onKeyDown={numericFilter}
                  value={data.secondPurchaseRequirement || ""}
                  onChange={handleChange}
                  required={additionalFieldRequired ? true : false}
                  placeholder="Should be 1 to 5 digits"
                  error={fieldType === "secondPurchaseRequirement"}
                  helperText={fieldType === "secondPurchaseRequirement" && "Second Purchase Requirement should be 1 to 5 digits and should not contain decimal or minus value"}
                />
                <TextField sx={{ mt: 2 }} id="secondPurchaseRequirementCode" name="secondPurchaseRequirementCode" type="number" label="Second Purchase Requirement Code" variant="outlined" fullWidth
                  onKeyDown={numericFilter}
                  value={data.secondPurchaseRequirementCode || ""}
                  onChange={handleChange}
                  required={additionalFieldRequired ? true : false}
                  placeholder="Should be 1 digit"
                  error={fieldType === "secondPurchaseRequirementCode"}
                  helperText={fieldType === "secondPurchaseRequirementCode" && "Second Purchase Requirement Code should be 1 digit"}
                />
                <TextField sx={{ mt: 2 }} id="secondPurchaseFamilyCode" name="secondPurchaseFamilyCode" type="number" label="Second Purchase Family Code" variant="outlined" fullWidth
                  onKeyDown={numericFilter}
                  value={data.secondPurchaseFamilyCode || ""}
                  onChange={handleChange}
                  required={additionalFieldRequired ? true : false}
                  placeholder="Should be 3 digits"
                  error={fieldType === "secondPurchaseFamilyCode"}
                  helperText={fieldType === "secondPurchaseFamilyCode" && "Second Purchase Family Code should be 3 digits and should not contain decimal or minus value"}
                />
                <Box sx={{ display: "flex", alignItems: "flex-end", mt: 2, mb: 3 }}>
                  <Autocomplete
                    disablePortal
                    id="secondCompanyPrefix"
                    options={companyPrefixes}
                    name="secondCompanyPrefix"
                    onChange={handleSecondDropdownChange}
                    fullWidth
                    required
                    autoSelect={true}
                    loading={companyPrefixes.length === 0 ? true : false}
                    renderInput={(params) => <TextField fullWidth {...params}
                      label="Second Purchase GS1 Company Prefix"
                      required={additionalFieldRequired ? true : false}
                    />}
                  />
                  <Tooltip className={isAdmin ? "" : "hide"} title="Add Second Purchase GS1 Company Prefix" sx={{ ml: 2, my: "auto" }} onClick={() => navigate("/companyprefixes/login")} >
                    <IconButton>
                      <AddIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
              <Grid container spacing={2} >
                <Grid item xs={6} md={6} lg={6}>
                  <TextField sx={{ mt: 2 }} id="startDate" name="startDate" type="date" label="Start Date" variant="outlined" focused fullWidth
                    onChange={handleDateChange}
                  />
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                  <TextField sx={{ mt: 2 }} id="expiryDate" name="expiryDate" type="date" label="Expire Date" variant="outlined" focused fullWidth
                    onChange={handleDateChange}
                  />
                </Grid>
              </Grid>
              <FormGroup sx={{ mt: 2 }}>
                <FormControlLabel style={{ width: "fit-content" }} control={<Checkbox onChange={e => setShowSerialNumber(!showSerialNumber)} />} label="Have a Serial Number?" />
              </FormGroup>
              <Box display={showSerialNumber ? "" : "none"} >
                <TextField sx={{ mb: 3 }} id="serialNumber" name="serialNumber" type="number" label="Serial Number" variant="outlined" fullWidth
                  onKeyDown={numericFilter}
                  value={data.serialNumber || ""}
                  onChange={handleChange}
                  placeholder="Should be 6 to 15 digits"
                  error={fieldType === "serialNumber"}
                  helperText={fieldType === "serialNumber" && "Serial Number should be 6 to 15 digits and should not contain decimal or minus value"}
                />
              </Box>
              <FormGroup>
                <FormControlLabel style={{ width: "fit-content" }} control={<Checkbox onChange={e => setShowRetailer(!showRetailer)} />} label="Have a Retailer Identification?" />
              </FormGroup>
              <Box display={showRetailer ? "" : "none"} >
                <Box sx={{ display: "flex", alignItems: "flex-end", mb: 3 }}>
                  <Autocomplete
                    disablePortal
                    id="RetailerCompanyPrefix"
                    options={companyPrefixes}
                    name="RetailerCompanyPrefix"
                    onChange={handleRetailerDropdownChange}
                    fullWidth

                    autoSelect={true}
                    loading={companyPrefixes.length === 0 ? true : false}
                    renderInput={(params) => <TextField fullWidth {...params}
                      label="Retailer GS1 Company Prefix"
                    />}
                  />
                  <Tooltip className={isAdmin ? "" : "hide"} title="Add Retailer GS1 Company Prefix" sx={{ ml: 2, my: "auto" }} onClick={() => navigate("/companyprefixes/login")} >
                    <IconButton>
                      <AddIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
              <FormGroup sx={{ mt: 2 }}>
                <FormControlLabel style={{ width: "fit-content" }} control={<Checkbox onChange={e => setFreeCoupon(!freeCoupon)} />} label="Is this a free Coupon?" />
                <FormControlLabel style={{ width: "fit-content" }} control={<Checkbox onChange={e => setLimitedPerOne(!limitedPerOne)} />} label="Limited one per customer?" />
              </FormGroup>
              <Grid container spacing={2} >
                <Grid item xs={6} md={6} lg={6}>
                  <TextField id="saveValueCode" name="saveValueCode" type="number" label="Save Value Code" placeholder="Should be 0 or 1" variant="outlined" fullWidth
                    onKeyDown={numericFilter}
                    value={data.saveValueCode || ""}
                    onChange={handleChange}
                    required={valueCodeFlagAppliesRequired ? true : false}
                    error={fieldType === "saveValueCode"}
                    helperText={fieldType === "saveValueCode" && "Save Value Code should be 0 or 1"}
                  />
                </Grid>
                <Grid item xs={6} md={6} lg={6} >
                  <TextField id="saveValueAppliesItem" name="saveValueAppliesItem" type="number" label="Save Value Applies to Which Item" variant="outlined" fullWidth
                    onKeyDown={numericFilter}
                    value={data.saveValueAppliesItem || ""}
                    onChange={handleChange}
                    required={valueCodeFlagAppliesRequired ? true : false}
                    placeholder="Should be 0 or 1"
                    error={fieldType === "saveValueAppliesItem"}
                    helperText={fieldType === "saveValueAppliesItem" && "Save Value Applies Item should 0 or 1"} />

                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6} md={6} lg={6}>
                  <TextField sx={{ mt: 2 }} id="storeCoupon" name="storeCoupon" type="number" label="Store Coupon Flag " placeholder="Should be 0 or 1" variant="outlined" fullWidth
                    onKeyDown={numericFilter}
                    value={data.storeCoupon || ""}
                    onChange={handleChange}
                    required={valueCodeFlagAppliesRequired ? true : false}
                    error={fieldType === "storeCoupon"}
                    helperText={fieldType === "storeCoupon" && "Store Coupon should be 0 or 1"}
                  />
                </Grid>
                <Grid item xs={6} md={6} lg={6} >
                  <TextField sx={{ mt: 2 }} id="multiplyFlag" name="multiplyFlag" type="number" label="Don’t Multiply Flag" variant="outlined" fullWidth
                    onKeyDown={numericFilter}
                    value={data.multiplyFlag || ""}
                    onChange={handleChange}
                    required={valueCodeFlagAppliesRequired ? true : false}
                    placeholder="Should be 0 or 1"
                    error={fieldType === "multiplyFlag"}
                    helperText={fieldType === "multiplyFlag" && "Don’t Multiply Flag should be 0 or 1"} />

                </Grid>
              </Grid>
              <Button className="customBtn" sx={{ mt: 4 }} size="" fullWidth type="submit" variant="contained" endIcon={<ChevronRightIcon />}>
                Generate
              </Button>

            </Box>
          </Grid>
          <Grid item xs={12} md={6} >

            {/* alert section */}
            <Collapse sx={{ mr: 1 }} in={open}>
              <Alert
                severity={alertType}
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                sx={{ mb: 2 }}
              >
                {alertText}
              </Alert>
            </Collapse>

            {/* barcode image section */}
            <Box sx={{}} >
              <Typography sx={{ mt: 1, ml: 5 }} component="h3" variant="h5">
                <strong>Generated Barcode</strong>
              </Typography>
              <Typography sx={{ ml: 5 }} component="h6" variant="h6">Barcode string:  <code className="barcodeString">
                {`(8110)${data.companyPrefix ? data.companyPrefix.length - 6 : ""}${data.companyPrefix ? data.companyPrefix : ""}${data.offerCode ? data.offerCode : ""}${data.saveValue ? data.saveValue?.length : ""}${data.saveValue ? data.saveValue : ""}${data.primaryPurchaseRequirement ? data.primaryPurchaseRequirement?.length : ""}${data.primaryPurchaseRequirement ? data.primaryPurchaseRequirement : ""}${data.primaryPurchaseRequirementCode ? data.primaryPurchaseRequirementCode : ""}${data.primaryPurchaseFamilyCode ? data.primaryPurchaseFamilyCode : ""}${data.additionalPurchaseRuleCode ? `1${data.additionalPurchaseRuleCode}${data.secondPurchaseRequirement ? data.secondPurchaseRequirement.length : ''}${data.secondPurchaseRequirement ? data.secondPurchaseRequirement : ''}${data.secondPurchaseRequirementCode ? data.secondPurchaseRequirementCode : ''}${data.secondPurchaseFamilyCode ? data.secondPurchaseFamilyCode : ''}${data.secondCompanyPrefix ? data.secondCompanyPrefix.length - 6 : ''}${data.secondCompanyPrefix ? data.secondCompanyPrefix : ''}` : ''}${data.expiryDate ? `3${data.expiryDate}` : ''}${data.startDate ? `4${data.startDate}` : ''}${data.serialNumber ? `5${data.serialNumber.length - 6}${data.serialNumber}` : ''}${data.retailerCompanyPrefix ? `6${data.retailerCompanyPrefix.length - 6}${data.retailerCompanyPrefix}` : ''}${data.saveValueCode ? `9${data.saveValueCode}${data.saveValueAppliesItem ? data.saveValueAppliesItem : ''}${data.storeCoupon ? data.storeCoupon : ''}${data.multiplyFlag ? data.multiplyFlag : ''}` : ''}`}</code>
              </Typography>
              <Stack sx={{ mt: 3, ml: 2 }}>
                <Container sx={isValueChanging ? { display: 'block' } : { display: 'none' }}>
                  <Skeleton variant="rectangular" width={200} height={120} />
                </Container>
                <Container sx={isValueChanging ? { display: 'none' } : { display: 'block' }}>
                  <img id="barcodeImage" src={src} alt="barcode" />
                </Container>
              </Stack>
              <Stack mt={1} ml={4} sx={isValueChanging || src === false ? { display: 'none' } : { display: 'block' }} >
                <p>{`Size: ${Object.keys(imageDimension).length === 0 ? '' : `${(imageDimension.w / document.getElementById("dpi")?.offsetHeight).toFixed(2)}” x ${(imageDimension.h / document.getElementById("dpi")?.offsetHeight).toFixed(2)}” `}`}</p>
              </Stack>
            </Box>

            {/* send mail and download section */}
            <Box onSubmit={(e) => handleSendMail(e)} component="form" sx={{ m: 5 }} >
              <TextField sx={{ mt: 0 }} id="email" name="email" type="email" label="Enter email to send barcode" variant="outlined" required
                fullWidth value={email} onChange={onEmailChange} />
              <Grid container sx={{ mt: 5 }} direction="row" justifyContent="space-between" alignItems="center" >
                <Grid item lg={4} md={2} xs={12} >
                  <FormControl sx={{ minWidth: 80 }} size="small">
                    <InputLabel id="fileType">File type</InputLabel>
                    <Select
                      labelId="fileType"
                      id="fileTypeId"
                      value={fileType}
                      label="File type"
                      name="fileType"
                      onChange={handleFileTypeChange}
                    >
                      <MenuItem value="png">PNG</MenuItem>
                      <MenuItem value="pdf">PDF</MenuItem>
                      <MenuItem value="eps">EPS</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item lg={8} md={9} xs={12} direction="row-reverse" justifyContent="flex-end" alignItems="center" >
                  <Grid container justifyContent="flex-end" alignItems="center">
                    <Button className={isValueChanging || src === false ? "customBtn-disabled" : "customBtnsm"} sx={{ mr: 1 }} disabled={isValueChanging || src === false ? true : false} type="submit" variant="contained"
                      startIcon={<MailIcon />}>
                      Mail
                    </Button>
                    {true ? <Button className={isValueChanging || src === false ? "customBtn-disabled" : "customBtnsm"} disabled={isValueChanging || src === false ? true : false} onClick={() => handleDownload(1)} variant="contained" endIcon={<SaveAltIcon />}>
                      DOWNLOAD
                    </Button> : ''}
                  </Grid>
                </Grid>
              </Grid>
            </Box>

            {/* product upload section for coupon */}
            <ProductCoupon type="Barcode" isValueChanging={isValueChanging} src={src} dataString={dataString} codeData={data} expiryDate={expiryDate} startDate={startDate} />

          </Grid>
        </Grid>
      </div>
    </MainWrapper>
  );
}

export default BarcodeExpandedStacked;
