import React from "react";
import ManufacturerImg from "../../assets/images/landing_page/manufacturer-img.png";
import ConsumerImg from "../../assets/images/landing_page/consumer-img.png";
import CheckIcon from "../../assets/images/landing_page/check-icon.png";
import SalesIcon from "../../assets/images/landing_page/sales-icon.png";
import BenefitIcon from "../../assets/images/landing_page/benefit-icon.png";
import BoostIcon from "../../assets/images/landing_page/boost-icon.png";
import UserCentricIcon from "../../assets/images/landing_page/user-centric-icon.png";
import NewProductImg from "../../assets/images/landing_page/newProduct-img.png";

export const Benefits = () => {
  return (
    <div className="section benefits-section position-relative" id="benefits">
      <div className="container position-relative">
        <div className="bg-shadow shadow1"></div>
        <div className="bg-shadow shadow2"></div>
        <div className="title-section text-center">
          <h3>Iclipcoupons Service Benefits</h3>
          <h2>Our benefits to manufacturers and consumers</h2>
        </div>
        <div className="benefit-content-area">
          <div className="row about-row benefit-row1">
            <div className="col-xs-12 col-lg-6">
              <div className="benefit-text-area">
                <div className="benefit-icon">
                  <img src={BenefitIcon} className="img-fluid" alt="Quotes Icon" />
                </div>
                <h3 className="benefit-name">Manufacturers</h3>
                <p className="benefit-text">Our platform offers a one-stop solution for manufacturers to launch and distribute coupons, while consumers can easily browse, add, and save on the items they need.</p>
                <ul className="benefit-list">
                  <li>Increase Product Sales</li>
                  <li>Reach A Wider Audience</li>
                  <li>Boost Brand Visibility</li>
                </ul>
              </div>
            </div>
            <div className="col-xs-12 col-lg-6">
              <div className="benefit-img-area position-relative">
                <div className="feature-block benefit-feature1 position-absolute">
                  <img src={SalesIcon} className="img-fluid" alt="Sales Icon" />
                  <span>Increase product sales</span>
                </div>
                <img src={UserCentricIcon} className="img-fluid feature-icon1" alt="Feature Icon" />
                <img src={BoostIcon} className="img-fluid feature-icon2" alt="Feature Icon" />
                <div className="rectangle-box1"></div>
                <img src={ManufacturerImg} className="img-fluid main-img" alt="Manufacturers Image" />
              </div>
            </div>
          </div>
          <div className="row about-row benefit-row2">
            <div className="col-xs-12 col-lg-6">
              <div className="benefit-img-area consumer-img position-relative">
                <div className="feature-block benefit-feature2 position-absolute">
                  <img src={CheckIcon} className="img-fluid" alt="Check Icon" />
                  <span>Save money on purchases</span>
                </div>
                <img src={NewProductImg} className="img-fluid feature-icon3" alt="Feature Icon" />
                <div className="rectangle-box2"></div>
                <img src={ConsumerImg} className="img-fluid main-img" alt="Consumers Image" />
              </div>
            </div>
            <div className="col-xs-12 col-lg-6">
              <div className="benefit-text-area">
                <div className="benefit-icon">
                  <img src={BenefitIcon} className="img-fluid" alt="Quotes Icon" />
                </div>
                <h3 className="benefit-name">Consumers</h3>
                <p className="benefit-text">Our platform offers a one-stop solution for manufacturers to launch and distribute coupons, while consumers can easily browse, add, and save on the items they need.</p>
                <ul className="benefit-list">
                  <li>Save Money On Purchases</li>
                  <li>Simplify Coupon Usage</li>
                  <li>Discover New Products</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
