import React from "react";
import Image from 'react-bootstrap/Image';
import CheckIcon from "../../assets/images/landing_page/circle-check-icon.png";

export const Features = () => {
  return (
    <div className="feature-section" id="features">
      <div className="container">
        <div className="features-area">
          <div className="row">
            <div className="col-xs-12 col-lg-4">
              <div className="feature-box">
                <div className="icon">
                  <Image src={CheckIcon} alt="Icon" />
                </div>
                <div className="feature-content">
                  <h3 className="feature-title">Innovation</h3>
                  <p>Embracing cutting-edge technology to revolutionize the coupon industry.</p>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-lg-4">
              <div className="feature-box">
                <div className="icon">
                  <Image src={CheckIcon} alt="Icon" />
                </div>
                <div className="feature-content">
                  <h3 className="feature-title">Convenience</h3>
                  <p>Making the shopping experience hassle-free and time-saving.</p>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-lg-4">
              <div className="feature-box">
                <div className="icon">
                  <Image src={CheckIcon} alt="Icon" />
                </div>
                <div className="feature-content">
                  <h3 className="feature-title">Trust</h3>
                  <p>Building a reliable platform for both manufacturers and consumers.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};