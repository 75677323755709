// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-title h1 {
  font-size: 30px;
  margin: 0px;
}

.loaderParent {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.productcategory-img {
  overflow: hidden;
  position: relative;
  border-radius: 20px;
  display: inline-block;
}
.productcategory-img img {
  max-width: 100%;
}
.productcategory-img .actions {
  top: 50%;
  right: 0;
  opacity: 1;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
  transition: ease-out all 0.3s;
  -o-transition: ease-out all 0.3s;
  -ms-transition: ease-out all 0.3s;
  -moz-transition: ease-out all 0.3s;
  -webkit-transition: ease-out all 0.3s;
  transform: translateY(-50%);
}
.productcategory-img .actions button {
  min-width: unset;
}
.productcategory-img .actions .replace {
  color: #FFF;
}
.productcategory-img .actions .remove {
  color: red;
}`, "",{"version":3,"sources":["webpack://./src/pages/productCategory/ProductCategory.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,WAAW;AACb;;AAEA;EACE,aAAa;EACb,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,mBAAmB;EACnB,qBAAqB;AACvB;AACA;EACE,eAAe;AACjB;AACA;EACE,QAAQ;EACR,QAAQ;EACR,UAAU;EACV,aAAa;EACb,kBAAkB;EAClB,mBAAmB;EACnB,sBAAsB;EACtB,uBAAuB;EACvB,8BAA8B;EAC9B,6BAA6B;EAC7B,gCAAgC;EAChC,iCAAiC;EACjC,kCAAkC;EAClC,qCAAqC;EACrC,2BAA2B;AAC7B;AACA;EACE,gBAAgB;AAClB;AACA;EACE,WAAW;AACb;AACA;EACE,UAAU;AACZ","sourcesContent":[".page-title h1 {\n  font-size: 30px;\n  margin: 0px;\n}\n\n.loaderParent {\n  height: 100vh;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n.productcategory-img {\n  overflow: hidden;\n  position: relative;\n  border-radius: 20px;\n  display: inline-block;\n}\n.productcategory-img img {\n  max-width: 100%;\n}\n.productcategory-img .actions {\n  top: 50%;\n  right: 0;\n  opacity: 1;\n  display: flex;\n  position: absolute;\n  align-items: center;\n  flex-direction: column;\n  justify-content: center;\n  background: rgba(0, 0, 0, 0.7);\n  transition: ease-out all 0.3s;\n  -o-transition: ease-out all 0.3s;\n  -ms-transition: ease-out all 0.3s;\n  -moz-transition: ease-out all 0.3s;\n  -webkit-transition: ease-out all 0.3s;\n  transform: translateY(-50%);\n}\n.productcategory-img .actions button {\n  min-width: unset;\n}\n.productcategory-img .actions .replace {\n  color: #FFF;\n}\n.productcategory-img .actions .remove {\n  color: red;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
