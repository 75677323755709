import React from "react";
import "./Coupon.css";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";

export const Coupon = ({ data, handleCouponDelete }) => {
  const {
    barcode_qr_code,
    offer_detail,
    expiry_date,
    product,
    coupon_id,
    manufacturer_id,
  } = data;
  return (
    <div className="couponContainer">
      <div className="imageContainer">
        <img className="productImage" src={product?.product_images[0]?.image_url} />
      </div>
      <div className="textContainer">
        <h6 className="productName">{product?.name}</h6>
        <div className="expiryContainer">
          <CalendarMonthIcon fontSize="small" />
          <p className="expiryDate">{expiry_date ? expiry_date : "N/A"}</p>
        </div>
      </div>
      <div className="barcodeContainer">
        <div className="barcodeParent">
          <img className="barcodeImage" src={barcode_qr_code?.image_url} alt="barcode" />
        </div>
        <div className="offerParent">
          <p className="expiryDate">Amount saved:</p>
          <p className="offerAmount">{offer_detail ? offer_detail : "N/A"}</p>
        </div>
      </div>
      {/* <div className="couponOverlay"></div> */}
      <div className="button text-center ">
        <Button variant="outlined" color="error" aria-label="delete" onClick={() => handleCouponDelete(manufacturer_id, coupon_id)}>
          Delete
        </Button>
      </div>
    </div>
  );
};
