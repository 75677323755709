// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.itemContainer {
  gap: 10px;
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  align-items: flex-start;
  justify-content: center;
  /* border-radius: 10px; */
  /* border: 1px solid #ccc; */
}
.prefix .items {
  width: 100%;
  display: flex;
  padding: 5px 20px;
  align-items: center;
  border-radius: 30px;
  flex-direction: unset;
  border: 1px solid #ccc;
  transition: ease-out all 0.3s;
  justify-content: space-between;
}
.items.active,
.items:hover {
  background-color: rgb(239, 239, 239);
}
.itemContainer.prefix {
  overflow: auto;
  max-height: 580px;
}
`, "",{"version":3,"sources":["webpack://./src/components/categoryItems/CategoryItems.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,YAAY;EACZ,aAAa;EACb,eAAe;EACf,gBAAgB;EAChB,uBAAuB;EACvB,uBAAuB;EACvB,yBAAyB;EACzB,4BAA4B;AAC9B;AACA;EACE,WAAW;EACX,aAAa;EACb,iBAAiB;EACjB,mBAAmB;EACnB,mBAAmB;EACnB,qBAAqB;EACrB,sBAAsB;EACtB,6BAA6B;EAC7B,8BAA8B;AAChC;AACA;;EAEE,oCAAoC;AACtC;AACA;EACE,cAAc;EACd,iBAAiB;AACnB","sourcesContent":[".itemContainer {\n  gap: 10px;\n  padding: 5px;\n  display: flex;\n  flex-wrap: wrap;\n  margin-top: 30px;\n  align-items: flex-start;\n  justify-content: center;\n  /* border-radius: 10px; */\n  /* border: 1px solid #ccc; */\n}\n.prefix .items {\n  width: 100%;\n  display: flex;\n  padding: 5px 20px;\n  align-items: center;\n  border-radius: 30px;\n  flex-direction: unset;\n  border: 1px solid #ccc;\n  transition: ease-out all 0.3s;\n  justify-content: space-between;\n}\n.items.active,\n.items:hover {\n  background-color: rgb(239, 239, 239);\n}\n.itemContainer.prefix {\n  overflow: auto;\n  max-height: 580px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
