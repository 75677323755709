import React, { memo, useState }  from "react";
import './layout.css';
import Sidebar from "./sidebar";
import Header from "./header";

const MainWrapper = memo(({ children }) => {
  const [isToggled, setToggled] = useState(false);

  const handleButtonClick = () => {
    setToggled(!isToggled);
  };
  return (
    <div className={`page-wrapper ${isToggled ? ' toggled' : ''}`}>
      <Sidebar />
      <div className="main scrollbar-2">
        <Header onButtonClick={handleButtonClick} />
        {children}
      </div>
    </div>
  );
})

export default MainWrapper;