import React, { useState } from "react";
import './auth.css'
import Container from 'react-bootstrap/Container';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from '@mui/material/Button';
import Logo from "../../assets/images/landing_page/logo.png";
import { Link, useNavigate } from "react-router-dom";
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import { Auth } from 'aws-amplify';

function ForgotPassword() {
    const [email, setEmail] = useState(null)
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false);
    const [errorText, setErrorText] = useState("")

    const navigate = useNavigate()

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)

        try {
            const data = await Auth.forgotPassword(email);
            setLoading(false)
            navigate("/reset-password");
            return;
        } catch (err) {
            console.log("auth error: ", err);
            setLoading(false)

            if (err.name === "UserNotFoundException") {
                setErrorText("User does not exist.")
                setOpen(true)
                return;
            }

            setErrorText("Something went wrong, please try again later.")
            setOpen(true)
            return;
        }
    }

    return (
        <section className="full-page-layout">
            <Container fluid className="p-md-0">
                <Row className="m-md-0 justify-content-center">
                    <Col xs={12} md={4} className="bg-white p-md-0">
                        <div className="login-area full-height">
                            <div className="form-area">
                                <Collapse in={open}>
                                    <Alert severity="error"
                                        action={
                                            <IconButton
                                                aria-label="close"
                                                color="inherit"
                                                size="small"
                                                onClick={() => {
                                                    setOpen(false);
                                                }}
                                            >
                                                <CloseIcon fontSize="inherit" />
                                            </IconButton>
                                        }
                                        sx={{ mb: 2 }}
                                    >
                                        {errorText}
                                    </Alert>
                                </Collapse>
                                <div className="text-center mb-4">
                                    <img className="authLogo" onClick={() => navigate("/")} src={Logo} alt="iClipCoupons" />
                                </div>
                                <h3 className="mb-4 auth-title">Forgot your password?</h3>
                                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="email"
                                        type="email"
                                        label="Email"
                                        name="email"
                                        autoFocus={true}
                                        autoComplete="email" value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        sx={{ mt: 3, mb: 2 }}
                                    >
                                        {loading ? "loading..." : "Submit"}
                                    </Button>
                                    <Grid container>
                                        <Grid item>
                                            <Link to="/login/barcode" className="auth-link">
                                                {"Back to login"}
                                            </Link>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default ForgotPassword;
