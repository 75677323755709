import React from 'react';
import { Document, Page, Text, View, StyleSheet, Font } from '@react-pdf/renderer';
import { TableRow } from '../../components/TableRow';
import font from "../../assets/fonts/Arimo-Bold.ttf"

Font.register({
    family: "arial",
    src: font
});


// Create Document Component
export const MyQrDocument = ({ data, string, type }) => {


    // console.log("data string:- ", data)

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.sectionHeading}>
                    <Text style={styles.heading} >QR Validator Report</Text>
                </View>
                <View style={styles.section}>
                    <Text style={styles.text}>No Value Code or UPCA</Text>
                    <Text style={styles.text}>Final Standard QR</Text>
                    <Text style={styles.text}>{`Current Symbol Decode: ${string} (${string?.length} digits)`}</Text>
                </View>
                <View style={styles.sectionTable}>
                    <TableRow first={"Coupon Format"} second={type === 0 ? "Digital" : "Paper"} />
                    <TableRow first={"Coupon Funder ID"} second={data?.couponFunderId} />
                    <TableRow first={"Offer Code"} second={data?.offerCode} />
                    <View style={styles.lastTableRow}>
                        <View style={styles.firstRowItem}>
                            <Text style={styles.tableText}>Serial Number</Text>
                        </View>
                        <View style={styles.secRowItem}>
                            <Text style={styles.tableText}>{data?.serialNumber}</Text>
                        </View>
                        <View style={styles.thirdRowItem}>
                            <Text style={styles.tableText}></Text>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    )
}

// Create styles
const styles = StyleSheet.create({
    page: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#fff',
        padding: '25px',
        fontFamily: "arial",
    },
    sectionHeading: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '30px',
    },
    heading: {
        fontSize: '14px',
        fontWeight: '800',
    },
    section: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        marginBottom: '30px',
    },
    text: {
        fontSize: '9px',
        fontWeight: 'bold',
        marginTop: "5px",
    },
    sectionTable: {
        display: 'flex',
        flexDirection: 'column',
    },
    lastTableRow: {
        display: 'flex',
        flexDirection: 'row',
        borderWidth: '1.5px',
        borderColor: 'black',
        borderStyle: 'solid',
    },
    firstRowItem: {
        width: '35%',
        padding: '1px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        borderRightWidth: '1px',
        borderRightColor: 'black',
        borderRightStyle: 'solid',
    },
    secRowItem: {
        width: '25%',
        padding: '1px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        borderRightWidth: '1px',
        borderRightColor: 'black',
        borderRightStyle: 'solid',
    },
    thirdRowItem: {
        width: '40%',
        padding: '1px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    borderBottom: {

        borderBottomWidth: '1px',
        borderBottomColor: 'black',
        borderBottomStyle: 'solid',
    },
    tableText: {
        fontSize: '9px',
        fontWeight: 'bold',
        marginLeft: '3px'
    },

});