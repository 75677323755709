// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-title h1 {
  font-size: 30px;
  margin: 0px;
}
.barcodeString {
  color: black;
}
#dpi {
  height: 1in;
  left: -100%;
  position: absolute;
  top: -100%;
  width: 1in;
}
#pdf {
  height: 1in;
  left: -100%;
  position: absolute;
  top: -100%;
  width: 1in;
}
.loaderParent {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.product-Image {
  max-height: 164px;
  border-radius: 20px;
}
.carousel-indicators {
  margin-bottom: -2rem;
}
.fileInput {
  width: 100% !important;
}
.hide {
  display: none !important;
}
`, "",{"version":3,"sources":["webpack://./src/pages/barcodeExpandedStacked/barcodeExpandedStacked.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,WAAW;AACb;AACA;EACE,YAAY;AACd;AACA;EACE,WAAW;EACX,WAAW;EACX,kBAAkB;EAClB,UAAU;EACV,UAAU;AACZ;AACA;EACE,WAAW;EACX,WAAW;EACX,kBAAkB;EAClB,UAAU;EACV,UAAU;AACZ;AACA;EACE,aAAa;EACb,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,iBAAiB;EACjB,mBAAmB;AACrB;AACA;EACE,oBAAoB;AACtB;AACA;EACE,sBAAsB;AACxB;AACA;EACE,wBAAwB;AAC1B","sourcesContent":[".page-title h1 {\n  font-size: 30px;\n  margin: 0px;\n}\n.barcodeString {\n  color: black;\n}\n#dpi {\n  height: 1in;\n  left: -100%;\n  position: absolute;\n  top: -100%;\n  width: 1in;\n}\n#pdf {\n  height: 1in;\n  left: -100%;\n  position: absolute;\n  top: -100%;\n  width: 1in;\n}\n.loaderParent {\n  height: 100vh;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n.product-Image {\n  max-height: 164px;\n  border-radius: 20px;\n}\n.carousel-indicators {\n  margin-bottom: -2rem;\n}\n.fileInput {\n  width: 100% !important;\n}\n.hide {\n  display: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
