export const getImageDimensions = (file) => {
    return new Promise(function (resolved, rejected) {
        var i = new Image()
        i.onload = function () {
            resolved({ w: i.width, h: i.height })
        };
        i.src = file
    })
}

export const haveNegativeOrDecimal = (value) => {
    if (value.includes(".") || value.includes("-")) {
        return true;
    } else {
        return false;
    }
}

export const numericFilter = (e) => {
    var code = (e.which) ? e.which : e.keyCode;
    if (code > 31 && (code < 48 || code > 57)) {
        e.preventDefault();
    }
}