// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css-1qvr50w-MuiTypography-root {
  /*width: 50px; 
  overflow: scroll;
  white-space: nowrap;
  text-overflow: clip; 
  word-wrap: break-word;*/
  font-size: 11px !important;
}
.css-nrdprl-MuiTypography-root {
  font-size: 11px !important;
  /* word-wrap: break-word; */
}
.css-o69gx8-MuiCardMedia-root {
  margin-left: auto !important;
  margin-right: auto !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
}
.css-185gdzj-MuiCardHeader-root {
  padding: 5px !important;
}
.css-185gdzj-MuiCardHeader-root span {
  word-break: break-all;
}
.css-1t6e9jv-MuiCardActions-root {
  padding: 0px !important;
}
.Barcode {
  height: 100%;
  padding: 1rem;
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);
}
.barcodeImage {
  max-width: 100%;
  max-height: 80px;
  object-fit: contain !important;
}`, "",{"version":3,"sources":["webpack://./src/components/card/Card.css"],"names":[],"mappings":"AAAA;EACE;;;;yBAIuB;EACvB,0BAA0B;AAC5B;AACA;EACE,0BAA0B;EAC1B,2BAA2B;AAC7B;AACA;EACE,4BAA4B;EAC5B,6BAA6B;EAC7B,kCAA6B;EAA7B,6BAA6B;AAC/B;AACA;EACE,uBAAuB;AACzB;AACA;EACE,qBAAqB;AACvB;AACA;EACE,uBAAuB;AACzB;AACA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,qCAAqC;EACrC,uCAAuC;AACzC;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,8BAA8B;AAChC","sourcesContent":[".css-1qvr50w-MuiTypography-root {\n  /*width: 50px; \n  overflow: scroll;\n  white-space: nowrap;\n  text-overflow: clip; \n  word-wrap: break-word;*/\n  font-size: 11px !important;\n}\n.css-nrdprl-MuiTypography-root {\n  font-size: 11px !important;\n  /* word-wrap: break-word; */\n}\n.css-o69gx8-MuiCardMedia-root {\n  margin-left: auto !important;\n  margin-right: auto !important;\n  width: fit-content !important;\n}\n.css-185gdzj-MuiCardHeader-root {\n  padding: 5px !important;\n}\n.css-185gdzj-MuiCardHeader-root span {\n  word-break: break-all;\n}\n.css-1t6e9jv-MuiCardActions-root {\n  padding: 0px !important;\n}\n.Barcode {\n  height: 100%;\n  padding: 1rem;\n  border-radius: 20px;\n  border: 1px solid rgba(0, 0, 0, 0.05);\n  box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);\n}\n.barcodeImage {\n  max-width: 100%;\n  max-height: 80px;\n  object-fit: contain !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
