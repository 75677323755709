import axios from "axios";
const URL = process.env.REACT_APP_BACKEND_API_URL;

export const imageUpload = async (base64, string, type) => {
    const email = JSON.parse(localStorage.getItem("user")).email;

    let data = JSON.stringify({ "data_string": base64, "name_string": string, "type": type, "user_email": email });

    let config = {
        method: 'post',
        url: `${URL}/manufacturer/save/`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    };

    const res = await axios(config)
        .then((res) => {
            return res.data.message;
        })
        .catch((err) => {
            console.log("image upload error: ", err);
            return null;
        });
    return res;
}

export const getImages = async (type) => {
    const user = localStorage.getItem("user")

    if (!user) return;
    const email = JSON.parse(user).email;

    const data = JSON.stringify({ "user_email": email, "type": type })

    var config = {
        method: 'post',
        url: `${URL}/manufacturer/fetch/`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    };

    const res = await axios(config)
        .then((res) => {
            return res.data.images;
        })
        .catch((err) => {
            console.log("error in getting images: ", err);
            // alert("Unable to get coupons, please try again later");
            return null;
        });
    return res;
}

export const deleteImages = async (type, name) => {
    const email = JSON.parse(localStorage.getItem("user")).email;

    const data = JSON.stringify({ "user_email": email, "type": type, "image_name": name })

    var config = {
        method: 'post',
        url: `${URL}/manufacturer/delete/`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    };

    const res = await axios(config)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log("error in deleting images: ", err);
            // alert("Unable to delete coupons, please try again later");
            return null;
        });
    return res;
}