import React from "react";
import "./Notfound.css";
import notFoundImage from "../../assets/images/not-found.jpg";
import { Link } from "react-router-dom";

export const NotFound = () => {
  return (
    <div className="notFoundContainer">
      <img className="notFoundImage" src={notFoundImage} alt="404 not found" />
      <Link to="/login/barcode" className="auth-link">
        {"Back to Login"}
      </Link>
    </div>
  );
};
