import React from "react";
// import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Header } from "../../components/landing/header";
import { Hero } from "../../components/landing/hero";
import { Features } from "../../components/landing/features";
import { About } from "../../components/landing/about";
import { Benefits } from "../../components/landing/benefits";
import { Contact } from "../../components/landing/contact";
import { Footer } from "../../components/landing/footer";
import "./Landing.css";

const Landing = () => {
  return (
    <div>
      <Header />
      <Hero />
      <Features />
      <About />
      <Benefits />
      <Contact />
      <Footer />
    </div>
  );
};

export default Landing;