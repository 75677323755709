import React from "react";
import AboutImg from "../../assets/images/landing_page/about-img.png";
import CheckIcon from "../../assets/images/landing_page/check-icon.png";
import QuotesIcon from "../../assets/images/landing_page/quotes-icon.png";

export const About = () => {
  return (
    <div className="section about-section pb-0" id="about">
      <div className="container">
        <div className="title-section text-center">
          <h3>Iclipcoupons Overview</h3>
          <h2>A <span>one-stop solution</span> for manufacturers to launch and distribute coupons</h2>
        </div>
        <div className="about-content-area">
          <div className="row about-row">
            <div className="col-xs-12 col-lg-6">
              <div className="about-img-area position-relative">
                <div className="feature-block about-feature1 position-absolute">
                  <img src={CheckIcon} className="img-fluid" alt="Check Icon" />
                  <span>Sales conversion rate</span>
                </div>
                <img src={AboutImg} className="img-fluid main-img" alt="About Image" />
              </div>
            </div>
            <div className="col-xs-12 col-lg-6">
              <div className="about-text-area">
                <div className="quotes-img">
                  <img src={QuotesIcon} className="img-fluid" alt="Quotes Icon" />
                </div>
                <p className="about-text">We aim to disrupt the coupon industry by providing a digital platform that offers convenience and savings for consumers.</p>
                <div className="fun-facts">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="fun-box">
                        <h3 className="fact-number">2x</h3>
                        <p className="fact-text">sales conversion rate</p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="fun-box">
                        <h3 className="fact-number">150k</h3>
                        <p className="fact-text">cost savings</p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="fun-box">
                        <h3 className="fact-number">3,267</h3>
                        <p className="fact-text">hours reclaimed</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
